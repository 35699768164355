/* ==========================================================================
   Tools
     > Tables
   ========================================================================== */

// Responsive

@mixin t-table--responsive {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  margin: 0 0 56px;
  padding: 0;

  .c-table,
  table {
    width: 100%;
    margin-bottom: 0;
  }
}
