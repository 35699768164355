/* ==========================================================================
Components
  > Icons
========================================================================== */

// S

.c-icon--s {
  font-size: 16px;
  position: relative;
  right: -8px;
}

// M

.c-icon--l {
  font-size: 24px;
  position: relative;
  top: 2px;
  left: 4px;
}

// 14

.c-icon--14 {
  font-size: 14px !important;
}

// 22

.c-icon--22 {
  font-size: 22px !important;
  margin-right: 3px;
  position: relative;
  top: 2px;
}

// Left

.c-icon--left {
  position: relative;
  margin-right: 4px;
  left: -4px;
}
