/* ==========================================================================
Components
  > Badges
========================================================================== */

// Inherited

.badge {
  display: inline-block;
  min-width: 1.04167em;
  padding-right: 0.26042em;
  padding-left: 0.26042em;
  text-align: center;
  background-color: #ebebeb;
  border-radius: 100px;
}