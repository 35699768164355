/* ==========================================================================
Components
  > Bugs
========================================================================== */

// Inherited

.bug {
  font-size: 12px;
  background: #e31836;
  color: #fff;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  display: inline-block;
  line-height: 20px;
  text-align: center;
  vertical-align: 0.25em;
  box-shadow: 0 2px 2px rgba(0,0,0,0.15);
  position: absolute;
  top: -7px;
  left: -7px;
}