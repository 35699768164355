/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background: var(--color--primary--lightest);
  position: relative;

  @include bp-medium {
    .c-footer--primary {
      display: flex;
      flex-wrap: wrap;

      .col {
        margin-left: 0 !important;
      }
    }
  }

  h3 {
    font-size: 20px;
    position: relative;
    color: var(--color--text);
    text-decoration: none;
    margin: 0;

    @include bp-medium {
      font-size: 28px;
      margin-bottom: 24px;
    }

    button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      background: transparent;
      border: none;
      cursor: pointer;
      color: var(--color--primary);
      width: 100%;
      text-align: right;
      height: var(--tap-target--size);

      &[aria-expanded='true'] .far {
        transform: rotate(180deg);
      }

      @include bp-medium {
        display: none;
      }
    }

    a {
      font-weight: 500;
      color: #444;
      text-decoration: none;

      @include bp-medium {
        font-weight: 400;
        color: var(--color--primary);
      }
    }

    &.active span::before {
      content: '\ed8c';
    }
  }

  .footer-menu ul {
    display: none;
    margin-top: 16px;
    margin-bottom: 0;

    @include bp-medium {
      display: block;
      margin-bottom: 0;
    }

    &.active {
      display: block;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    font-size: 18px;

    @include bp-medium {
      display: block;
      border-bottom: 0;
    }

    li {
      margin-bottom: 12px;
      list-style-type: none;

      a {
        color: #444;
        font-family: var(--font--body);
        text-decoration: none;

        &:hover {
          color: var(--color--primary);
          text-decoration: underline;
        }
      }
    }

    &.c-footer-social {
      font-size: 0px; // Removes whitespace
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0;

        a {
          font-size: 18px;
          height: 38px;
          width: 38px;
          display: block;
          line-height: 38px;
          border: 1px solid var(--color--text);
          text-align: center;
          color: var(--color--text);
          transition: all 100ms ease-in-out;
          border-radius: 50%;
          margin: 0 12px 0 0;

          &:hover {
            background: var(--color--primary);
            border-color: var(--color--primary);
            color: #fff;
            text-decoration: none !important;
          }
        }
      }
    }
  }
}

// Secondary

.c-footer--secondary {
  font-size: 16px;
  margin: 0;
  padding: 36px 0;

  .col:last-child {
    margin-bottom: 0;
  }

  @include bp-medium-large {
    display: flex;
    align-items: center;

    .col {
      flex-shrink: 0;
      margin-bottom: 0;
    }
  }

  .c-logo {
    width: 104px;

    @include bp-medium-large {
      width: 148px;
    }
  }

  ul,
  p {
    font-size: 16px;
    margin-bottom: 0px;

    @include bp-medium-large {
      margin-bottom: 0;
    }
  }

  ul li {
    margin-bottom: 0;
  }
}
