/* ==========================================================================
   #CTA BANNER
   ========================================================================== */

.c-cta-banner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 40px 0;
  width: 100%;
  max-width: 844px;

  @include bp-medium-large {
    flex-direction: row;
    margin: auto;
  }

  h2 {
    color: #fff;
    font-size: 24px;
    line-height: 30px;
    flex-shrink: 0;

    @include bp-medium {
      font-size: 40px;
      line-height: 48px;
    }

    @include bp-medium-large {
      margin: 0;
      padding-right: var(--gutter);
    }
  }

  .c-btn {
    width: 100%;

    @include bp-small {
      width: auto;
      min-width: 300px;
    }

    @include bp-medium-large {
      margin-left: auto;
    }
  }

  .c-cta-banner__wysiwyg {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;

    @include bp-large {
      margin-left: 56px;
      align-items: flex-start;
    }
  }

  a,
  p {
    color: #fff;
  }

  p {
    flex-shrink: 0;
    margin: 0;

    @include bp-medium-large {
      margin-top: 12px;
    }
  }

  ul {
    margin: 32px 0 0 18px;
    text-align: left;
  }

  a {
    position: relative;

    &:after {
      content: '';
      height: 2px;
      width: 100%;
      background: #fff;
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
    }
  }
}
