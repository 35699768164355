/* ==========================================================================
Components
  > Cards
========================================================================== */

// Base

.c-card {
  @include t-card;
  @include t-card--vertical;

  // Media

  .c-card__media {
    @include t-card__media;

    // LMS

    &.c-media--lms {
      @include t-card__media--lms;
    }
  }

  // Body

  .c-card__body {
    @include t-card__body;
  }

  // Action

  .c-card__action {
    @include t-card__action;
  }

  // Action -- Product

  &.c-card--product {
    height: auto;

    .c-card__action {
      padding-top: 0;
    }
  }

  // Sub Text

  .c-card__sub-text {
    @include t-card__sub-text;
  }

  // Image

  &.c-card--image {
    @include t-card--image;
  }

  // No Image

  &.c-card--no-image {
    @include t-card--no-image;
  }

  // Borders

  &.c-card--b--l {
    @include t-card--borders;
  }

  &.c-card--elevated {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
}

// @xl

@include bp-xlarge {
  .c-card--flow--dynamic {
    @include t-card--horizontal;
  }
}

/* ==========================================================================
  3 col
========================================================================== */

// 1 card

.c-article--main.l5 {
  .c-card--01 {
    .c-card.c-card--image {
      @include bp-medium {
        @include t-card--horizontal;
      }
      @include bp-large {
        @include t-card--vertical;

        .c-card__sub-text {
          padding: 0;
          margin-top: 32px;
        }
      }
      @include bp-xlarge {
        @include t-card--horizontal;
      }
    }
  }
}

// 2 cards

.c-article--main.l5 {
  .c-cards--02 {
    @include bp-medium {
      display: flex;

      .c-card {
        @include t-card--vertical;
      }
    }

    @include bp-large {
      display: block;

      .col {
        @include t-reset--col--100;
      }
    }

    @include bp-xlarge {
      display: flex;
    }
  }
}

// 3 cards

.c-article--main.l5 {
  .c-cards--03 {
    @include bp-medium {
      .col {
        @include t-reset--col--100;
      }
      .c-card {
        @include t-card--horizontal;
      }
    }

    @include bp-large {
      display: block;

      .c-card {
        @include t-card--vertical;
      }
    }

    @include bp-xlarge {
      .c-card {
        @include t-card--horizontal;
      }
    }
  }
}

// LMS Promo

.c-article--main.l5 {
  .c-card.c-card--lms {
    @include bp-medium {
      @include t-card--horizontal;
    }

    @media (min-width: 1024px) and (max-width: 1279px) {
      @include t-card--vertical;

      .c-card__media {
        &.c-media--lms {
          margin: -24px -32px 24px -32px;
          padding-bottom: 8px;
          border-right: none;
          border-bottom: 1px solid rgba(144, 157, 185, 0.33);
        }
      }

      .c-card__body .c-btn {
        display: none;
      }

      .c-card__action {
        display: block;
      }
    }

    @include bp-xlarge {
      @include t-card--horizontal;
    }
  }
}

/* ==========================================================================
  2 col - RR (or left)
========================================================================== */

// 1 card

.c-article--main {
  &.l8,
  &.l9 {
    .c-card--01 {
      .c-card.c-card--image {
        @include bp-medium {
          @include t-card--horizontal;
        }
      }
    }
  }
}

// 2 cards

.c-article--main.l8 {
  .c-cards--02 {
    @include bp-medium {
      display: flex;

      .c-card {
        @include t-card--vertical;
      }
    }
  }
}

// 3 cards

.c-article--main.l8 {
  .c-cards--03 {
    @include bp-medium {
      .col {
        @include t-reset--col--100;
      }
      .c-card {
        @include t-card--horizontal;
      }
    }

    @include bp-large {
      display: block;
    }

    @include bp-xlarge {
      display: flex;

      .col {
        float: left;
        width: 33.333% !important;
      }
      .c-card {
        @include t-card--vertical;
      }
    }
  }
}

@include bp-medium {
  .c-article--main {
    &.l8,
    &.l9 {
      .c-card--lms {
        @include t-card--horizontal;
      }
    }
  }
}

/* ==========================================================================
  Full Width & 2 col
========================================================================== */

// 1 card

.c-article--main {
  &.c-col--100,
  &.l9 {
    .c-card--01 {
      .c-card.c-card--image {
        @include bp-medium {
          @include t-card--horizontal;
        }
      }
    }
  }
}

// 2 cards

.c-article--main {
  &.c-col--100,
  &.l9 {
    .c-cards--02 {
      @include bp-medium {
        display: flex;

        .c-card {
          @include t-card--vertical;
        }
      }

      @include bp-xlarge {
        .c-card {
          @include t-card--horizontal;
        }
      }
    }
  }
}

// 3 cards

.c-article--main {
  &.c-col--100,
  &.l9 {
    .c-cards--03 {
      @include bp-medium {
        .col {
          @include t-reset--col--100;
        }
        .c-card {
          @include t-card--horizontal;
        }
      }

      @include bp-large {
        display: block;
      }

      @include bp-xlarge {
        display: flex;

        .col {
          float: left;
          width: 33.333% !important;
        }
        .c-card {
          @include t-card--vertical;
        }
      }
    }
  }
}

// LMS Promo

.c-article--main.c-col--100 {
  .c-card--lms {
    @include bp-medium {
      @include t-card--horizontal;
    }
  }
}

/* ==========================================================================
  Full Bleed
========================================================================== */

// 1 card

.c-article--main.c-col--fb {
  .c-card--01 {
    .c-card.c-card--image {
      @include bp-medium {
        @include t-card--horizontal;
      }
    }
  }
}

// 2 cards

.c-article--main.c-col--fb {
  .c-cards--02 {
    @include bp-medium {
      display: flex;

      .c-card {
        @include t-card--vertical;
      }
    }

    @include bp-xlarge {
      .c-card {
        @include t-card--horizontal;
      }
    }
  }
}

// 3 cards

.c-article--main.c-col--fb {
  padding: 0; // Needs to stay - Still see form builder pages as these are losing padding.

  .c-cards--03,
  .c-cards--04 {
    @include bp-medium {
      .col {
        @include t-reset--col--100;
      }
      .c-card {
        @include t-card--horizontal;
      }
    }

    @include bp-large {
      display: block;
    }

    @include bp-xlarge {
      display: flex;

      .col {
        float: left;
        margin-left: 0;
      }
      .c-card {
        @include t-card--vertical;
      }
    }
  }

  @include bp-xlarge {
    .c-cards--03 .col {
      width: 33.333% !important;
    }

    .c-cards--04 .col {
      width: 25% !important;
    }
  }
}

/* ==========================================================================
  RR
========================================================================== */

// 1 card

.o-sidebar--r {
  .c-card.c-card--image {
    @include bp-medium {
      @include t-card--horizontal;
    }

    @include bp-large {
      @include t-card--vertical;

      .c-card__sub-text {
        padding: 0;
        margin-top: 32px;
      }
    }
  }

  // LMS Promo

  .c-card.c-card--lms {
    @include bp-medium {
      @include t-card--horizontal;
    }

    @include bp-large {
      @include t-card--vertical;

      .c-card__media {
        &.c-media--lms {
          margin: -24px -32px 24px -32px;
          padding-bottom: 8px;
          border-right: none;
          border-bottom: 1px solid rgba(144, 157, 185, 0.33);
        }
      }

      .c-card__body .c-btn {
        display: none;
      }

      .c-card__action {
        display: block;
        padding-top: 0 !important;
      }
    }
  }
}

/* ==========================================================================
  Horizontal
========================================================================== */

// @sm

@include bp-small-medium {
  .c-card--horizontal--sm {
    @include t-card--horizontal;
  }

  .u-height--unset--sm {
    min-height: unset !important;
  }
}

// @m

@include bp-medium {
  .c-card--horizontal--m {
    @include t-card--horizontal;
  }
}

// @ml

@include bp-medium-large {
  .c-card--horizontal--ml {
    @include t-card--horizontal;
  }
}

// @l

@include bp-large {
  .c-card--horizontal--l {
    @include t-card--horizontal;
  }
}

// @l

@include bp-large {
  .c-card--horizontal--assertive--l {
    flex-direction: row !important;

    &.c-card--image .c-card__media {
      position: relative !important;
      margin-right: 24px !important;
      margin-bottom: -24px !important;
      padding-bottom: 0 !important;
      min-height: 192px !important;
      width: 34.3450479233227% !important;
      min-width: 192px !important;
      border-radius: var(--global--radius) 0 0 var(--global--radius) !important;
    }
  }
}

// @xxl

@include bp-xxlarge {
  .c-card--horizontal--xxl {
    @include t-card--horizontal;
  }
}

@media (min-width: 1280px) and (max-width: 1335px) {
  .c-article--main {
    .has--bg--img {
      .c-card--horizontal--xl {
        .c-card__media {
          min-width: 100px;
        }
      }
    }
  }
}

/* ==========================================================================
  Vertical
========================================================================== */

// @sm

@include bp-medium-large {
  .c-card--vertical--ml {
    @include t-card--vertical;
  }
}

// @l

@include bp-large {
  .c-card--vertical--l {
    @include t-card--vertical;
  }
}

// Product

.c-card--product {
  width: 100%;
  max-width: 299px;
}

// Cards on bg img

.has--bg--img {
  .c-card {
    @include shadow(3);
    border: none;
  }

  .h3 {
    color: #fff;
  }
}

/*

  LMS Promotional

    CTA text edge-case @l & @l offset

    Hide context >= 1024px - < 1160px

    <a href="">VIEW <span class="c-btn__text--context">COURSE</span></a>

*/

@include bp-large {
  .offset-l3,
  .o-sidebar {
    .c-btn__text--context {
      display: none;

      @media (min-width: 1160px) {
        display: inline-block;
      }
    }
  }
}

/*
  Article Main adjustments
    Required when included in narrower columns
*/

@media (min-width: 1024px) and (max-width: 1279px) {
  // Main col - 3 col

  .c-article--main.l6 {
    .u-flex--m.u-cta--align--m {
      display: block;

      .m6,
      .ml6 {
        @include t-reset--col--100;
      }
    }

    .u-flex--l.u-cta--align--l {
      display: block;

      .l4 {
        @include t-reset--col--100;
      }
    }
  }

  // Main col - 2 col RR

  .c-article--main.l9 {
    .u-flex--l.u-cta--align--l {
      display: block;

      .l4 {
        @include t-reset--col--50;
      }
    }
  }
}

@media (min-width: 1280px) {
  .c-article--main.l6 {
    .u-flex--l.u-cta--align--l {
      display: block;

      .l4 {
        @include t-reset--col--50;
      }
    }
  }
}

// Aside

@include bp-large {
  aside {
    .u-flex--m.u-cta--align--m {
      display: block;

      .m6 {
        @include t-reset--col--100;

        .c-card {
          height: auto;
        }
      }
    }
  }
}
