/* ==========================================================================
   #TABLE
   ========================================================================== */

.c-table--responsive,
.tableContainer {
  @include t-table--responsive;

  // Base / Standard

  .c-table,
  table {

    th {
      color: #fff;
      text-align: left;
      padding: 8px 16px;
      font-weight: 400;
      border: 4px solid #fff;

      @include bp-medium {
        padding: 16px 24px;
      }

      a {
        color: #fff;
        text-decoration: none;

        &:hover {
          color: #fff;
        }
      }
    }

    thead th {
      font-size: 18.667px;
      font-weight: 700;
    }

     tbody th {
      color: #444;
      font-weight: 700;
    }

    td,
    th[scope="row"] {
      color: #444;
      padding: 8px 16px;
      background: var(--color--grey--light);
      border: 4px solid #fff;

      @include bp-medium {
        padding: 16px 24px;
      }
    }

    td {
        h4 {
        margin-bottom: 0.5rem;
      }

      p {
        margin-bottom: 1rem;
      }
    }

    thead {
      th {
        background: var(--color--primary--blue--light);
      }
    }

    // Alternating Rows

    &.c-table--alt-rows {

      tbody {
        tr:nth-child(even) {
          td,
          th[scope="row"] {
            background: #DADBDE;
          }
        }
      }
    }

    // Outline

    &.c-table--outline {

      th {
        border: 1px solid var(--color--primary--blue--light);
      }

      tbody {
        th,
        td {
          background: #fff;
          border: 1px solid #CECECE;
        }
      }
    }

    // Outline - Alternating Rows

    &.c-table--outline.c-table--alt-rows {

      tbody {
        tr:nth-child(even) {
          td,
          th[scope="row"] {
            background: #f1f1f1;
          }
        }
      }
    }
  }
}

// Captcha

.CaptchaTable {
  th,
  td {
    padding: 0;
    border: none;
    background: transparent;
  }
  img {
    margin: 0 auto 16px;
  }
}

// Table - Alt

.c-tbl--alt {

  th,
  td {
    border: 4px solid var(--color--grey--light);
  }

  th:first-child,
  td:first-child {
    border-left: none;
  }

  th:last-child,
  td:last-child {
    border-right: none;
  }
}

.c-tbl--alt td {
  background: #fff;  
}

// Table - Stripped

.c-tbl--stripped {

  th,
  td {
    display: block;
    background: none;
    padding: 0;
    border: none;
  }

  tr {
    display: block;
    margin-bottom: 1em;
  }
}

// Forum Content

.ForumContent {
  padding: 0;
  margin: -2px 0 0 0;
  display: block;
  overflow: hidden;
  border: none !important;
}

tr.Info {
  margin: 0 0 16px 0;
  display: block;
}

.Info td,
.Actions td,
.ForumDescription {
  display: block;
}

.Info td,
.Actions td {
  background: none;
  padding: 0;
  margin: 0;
}

.ForumName,
.GroupName,
.ForumDescription,
.GroupDescription {
  margin-bottom: 0;
}

.Actions {
  display: block;
  margin-bottom: 16px;
}

.ForumBreadCrumbs {
  display: block;
  margin-top: 16px;
}

.ThreadInfo,
.ForumInfo {
  min-width: 260px;
  
  h3 {
    margin-bottom: 0;
  }
}

a.ThreadName,
a.ForumName {
  text-decoration: none;
  color: #111;
}

// Hide Management Actions for larger screens. (Revisit to target no-touch only)

@include bp-large {

  .ThreadInfo,
  .ForumInfo {

    .ForumManage {
      opacity: 0;
      transition: var(--global--transition);
    }
  }

  .Thread:hover > .ThreadInfo > .ForumManage,
  .Forum:hover > .ForumInfo > .ForumManage {
    opacity: 1;
  }
}

a.ActionLink,
.PostTime {
  font-size: 15px;
  color: var(--color--grey--light);
}

a.ActionLink:hover {
  color: var(--color--tertiary);
}

.LastPost span:nth-child(2) {
  color: var(--color--grey--light);
}

.c-view-mode {
  float: left;

  @include bp-medium {
    float: right;
    text-align: right;
  }
}

.AvatarImage {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

// Replace unreachable Kentico output (table actions)

.c-tbl--actions-plain tbody {

  .unigrid-actions button {

    font-size: 14px !important;

    i {
      display: none;
    }
    
    &:hover {
      background: var(--color--secondary);
    }

    &:after {
      content: '';
    }

    &:before {
      font-family: var(--font--body);
      font-size: 14px !important;
    }

    &.js-edit:before {
      content: 'Edit';
    }

    &.js-delete:before {
      content: 'Delete';
    }
  }
}

// Vertical

.c-table--vertical {
  width: 100%;
  margin: 0 auto 16px;
  font-size: 16px;

  th {
    text-align: left;
  }
}

// Block

.c-table--block {
  text-align: left;

  th, tr, td {
    display: block;
    width: 100%;
  }

  tr {
    margin-bottom: 8px;
  }
}

// Block - @max m

@include bp-max-medium {

.c-table--block--max--m {

  th, tr, td {
    display: block;
    width: 100%;
  }

  tr {
    margin-bottom: 8px;
  }
}

}

// Inherited

.tableContainer {
  @include t-table--responsive;
}

div:not(.RadWindow):not(.iAPPSToolbar):not(#bar) .t10 {
  width: 10%;
}

div:not(.RadWindow):not(.iAPPSToolbar):not(#bar) table:not(.ContextMenuGroup):not(.ToolbarTopGroup):not(.ContextMenuBreak):not(.ContextMenuItem) th, div:not(.RadWindow):not(.iAPPSToolbar):not(#bar) table:not(.ContextMenuGroup):not(.ToolbarTopGroup):not(.ContextMenuBreak):not(.ContextMenuItem) td {
  text-align: left;
}

div:not(.RadWindow):not(.iAPPSToolbar):not(#bar) .table--bordered th, div:not(.RadWindow):not(.iAPPSToolbar):not(#bar) .table--bordered td {
  border: 1px solid #ebebeb;
}

div:not(.RadWindow):not(.iAPPSToolbar):not(#bar) .table--striped tbody tr:nth-of-type(2n+1) {
 background-color: #DADBDE;
}

div:not(.RadWindow):not(.iAPPSToolbar):not(#bar) .table--striped tbody tr td {
  background: none;
}