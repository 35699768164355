/* ==========================================================================
Components
  > Quick Links
========================================================================== */

.c-quicklinks {
  margin: 0 auto;
  padding: var(--padding--wrapper--quick-link);
  width: 100%;
  max-width: calc(1280px + var(--gutter) * 2);

  .row {
    display: flex;
    @include t-flex--wrap;
  }

  .c-quicklink {
    display: flex;
    flex-direction: column;

    @include bp-small {
      flex-direction: row;
    }

    // Img

    > .fal {
      flex: 112px 0 0;
      font-size: 56px;
      width: 112px;
      height: 112px;
      line-height: 112px;
      border-radius: 50%;
      border: 1px solid var(--color--primary);
      margin: 0 0 var(--gutter);
      color: var(--color--primary);
      text-align: center;
      border-radius: 100%;

      @include bp-small {
        margin: 0 var(--gutter) 0 0;
        align-self: flex-start;
      }
    }
    // Detail
    .c-quicklink__detail {
      .h5 {
        margin-bottom: 0.75rem;
        font-weight: 500;
      }

      .c-btn {
        font-size: 16px;
      }
    }
    // Mini
    &.c-quicklink--mini {
      > .fal {
        flex: 88px 0 0;
        font-size: 40px;
        width: 88px;
        height: 88px;
        line-height: 88px;

        @include bp-small {
          margin-right: calc(var(--gutter) / 2);
        }
      }
    }
  }
}

/*
  Article Main adjustments
    Required when included in narrower columns
*/

// 2 col - RR

@media (min-width: 1024px) and (max-width: 1279px) {
  .c-article--main {
    &.l8,
    &.l9 {
      .c-quicklinks--03 {
        .l4 {
          @include t-reset--col--50;
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1335px) {
  .c-article--main.l8 {
    .c-quicklinks--04 {
      .l3 {
        @include t-reset--col--50;
      }
    }
  }
}

// 3 col

@media (min-width: 1024px) {
  .c-article--main.l5 {
    .c-quicklinks .col {
      @include t-reset--col--100;
    }
  }
}
