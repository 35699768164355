/* ==========================================================================
   Utilities
     > Type
   ========================================================================== */

// 12

.u-type--12 {
  font-size: 12px;
}

// 16

.u-type--16 {
  font-size: 16px !important;
}

// 18

.u-type--18 {
  font-size: 18px;
}

// 20

.u-type--20 {
  font-size: 20px;
}

// 64

.u-type--64 {
  font-size: 64px;
}

// Tertiary

.u-type--tertiary {
  font-size: 16px;
  font-weight: 500;
}

// Medium

.u-type--medium {
  font-size: 20px !important;
  line-height: 28px;
  margin-bottom: 16px;
}

// Family - Medium

.u-family--medium {
  font-family: var(--font--medium);
}

// Text Transforms

.u-tt--initial {
  text-transform: initial;
}

// Results

.u-type--results {
  border: 1px solid var(--color--grey--light);
  display: inline-block;
  padding: 6px 8px;
  line-height: 1;
  border-radius: 32px;
  font-size: 16px;
}
