/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  width: 100%;

  // If bg colour

  // &[class*='u-bg-color--'] {
  //   padding: var(--padding--wrapper);
  // }
}

// Minor

.o-wrapper--minor {
  padding: var(--padding--wrapper--minor) 0;
}

// Minor - Aside

@include bp-large {
  aside .o-wrapper {
    padding: var(--padding--wrapper--minor) 0;
    margin-bottom: var(--gutter);
  }
}

@include bp-medium {
  .o-wrapper--minor--m {
    padding: var(--padding--wrapper--minor) 0;
  }
}

// Major (@max sm)

@include bp-max-small-medium {
  .o-wrapper--major--max--sm {
    padding: var(--padding--wrapper--major);
  }
}

// Quick link

.o-wrapper--quick-link {
  padding: var(--padding--wrapper--quick-link);
}

// Hero

.o-wrapper--hero {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

// Tab

.o-wrapper--tab {
  display: flex;
  position: relative;
  flex-direction: column;

  @include bp-large {
    flex-direction: row;
  }
}

// Sticky

.o-wrapper--sticky {
  position: sticky;
  top: 0;
  transition: all 0.4s;
  z-index: 9;

  .o-container {
    display: flex;
    align-items: center;
  }
}

.scroll-up .o-wrapper--sticky {
  top: 80px;

  @media (min-width: 1280px) and (max-height: 900px) {
    top: 0;
  }
}

// Text Block

@include bp-max-medium-large {
  .o-wrapper--text-block {
    padding-left: var(--padding--wrapper);
    padding-right: var(--padding--wrapper);
  }
}

.c-col--100,
.c-article--main.l8 {
  .o-wrapper--has-bg {
    padding-left: var(--padding--wrapper);
    padding-right: var(--padding--wrapper);

    .c-media--50-50 {
      @include bp-small-medium {
        padding-bottom: var(--padding--wrapper);
      }
    }
  }
}

@include bp-small-medium {
  .c-col--fb {
    .o-wrapper--has-bg {
      .c-media--50-50 {
        padding-bottom: var(--padding--wrapper);
      }
    }
  }
}
