/* ==========================================================================
Components
  > Pagination
========================================================================== */

// hold, ul, li, select

.c-pagination__hold {
  margin: 0 0 16px 0;

  nav {
    display: none;

    @include bp-medium {
      display: flex;
      align-items: center;
    }

    @media (min-width: 1024px) and (max-width: 1279px) {
      flex-direction: column;
      align-items: flex-start;

      .c-dropdown {
        margin-top: 16px;
      }
    }

    @media (min-width: 1024px) and (max-width: 1093px) {
      .c-pagination > li {
        margin-right: 3px;
      }
    }
  }

  // ul

  .c-pagination {
    @include clearfix();
    width: auto;
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      display: inline-block;
      margin: 0 0 8px 0;
      width: 40px;
      height: 40px;
      line-height: 40px;
      color: #fff;
      text-align: center;
      border-radius: 50px;

      @include bp-medium {
        width: 48px;
        height: 48px;
        line-height: 48px;
        margin-right: 8px;
      }

      .far {
        font-size: 0.8rem;
      }
    }
  }

  // Select

  .c-dropdown {
    width: 112px;
    margin-top: 16px;

    @include bp-small {
      margin-top: -8px;
      margin-left: 8px;
    }
  }

  // Load More

  .loadmore {
    display: block;

    @include bp-medium {
      display: none;
    }
  }
}

// a, active

.c-pagination {
  > li > a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: block;
    text-decoration: none;
    color: #767676;
    border-radius: 50px;

    @include bp-medium {
      width: 48px;
      height: 48px;
      line-height: 48px;
    }
  }
}

// active

.c-pagination .active {
  background: var(--color--primary);
  color: #fff;

  &:hover {
    background: var(--color--primary);
  }
}

// hover

.c-pagination > li > a:hover {
  color: #fff;
  background: var(--color--primary);
}
