/* ==========================================================================
Settings
  > Typography
========================================================================== */

:root {
  --font__size--base: 20px;
  --line__height--base: 28px;
  --font--headings: 'CircularXXWeb-Regular', sans-serif;
  --font--body: 'CircularXXWeb-Regular', sans-serif;
  --font--medium: 'CircularXXWeb-Medium', sans-serif;
}

/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The font file(s) should be placed relative to the regular CSS file.
 *
 */

/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 * 
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * AspiraLiving.ca (monthly pageviews: < 250K)
 *
 */

/* complete */

@font-face {
  font-family: 'CircularXXWeb-Regular';
  src: url('../assets/fonts/circular/CircularXXWeb-Regular.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXWeb-Regular';
  src: url('../assets/fonts/circular/CircularXXWeb-Regular.woff2')
    format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-Medium';
  src: url('../assets/fonts/circular/CircularXXWeb-Medium.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXWeb-Medium';
  src: url('../assets/fonts/circular/CircularXXWeb-Medium.woff2')
    format('woff2');
}

/* subset */

@font-face {
  font-family: 'CircularXXSub-Regular';
  src: url('../assets/fonts/circular/CircularXXSub-Regular.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXSub-Regular';
  src: url('../assets/fonts/circular/CircularXXSub-Regular.woff2')
    format('woff2');
}

@font-face {
  font-family: 'CircularXXSub-Medium';
  src: url('../assets/fonts/circular/CircularXXSub-Medium.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXSub-Medium';
  src: url('../assets/fonts/circular/CircularXXSub-Medium.woff2')
    format('woff2');
}
