/* ==========================================================================
Vendor
  > Fancybox
========================================================================== */
a[data-fancybox] img {
  cursor: zoom-in;
}

.fancybox__container {
  --fancybox-color: #334155;
  --fancybox-bg: #fff;

  --fancybox-content-color: #6b7280;
  --fancybox-content-bg: #fff;

  --fancybox-thumbs-width: 48px;
  --fancybox-thumbs-ratio: 1;

  --carousel-button-svg-stroke-width: 1.5;
  --carousel-button-svg-filter: none;
}

/**
*  Navigation buttons
*/
.fancybox__nav {
  --carousel-button-bg: rgba(255, 255, 255, 0.64);

  --carousel-button-svg-width: 20px;
  --carousel-button-svg-height: 20px;

  --carousel-button-svg-stroke-width: 2;
}

.fancybox__nav .carousel__button.is-next {
  right: 30px;
}

.fancybox__nav .carousel__button.is-prev {
  left: 30px;
}

.carousel__button.is-close {
  top: 0;
  right: 0;
  z-index: 10;
}

.carousel__button svg {
  filter: none !important;
}

/**
*  Toolbar
*/

.fancybox__toolbar {
  padding: 0;

  background: var(--fancybox-bg) !important;
  text-shadow: none !important;

  --carousel-button-svg-width: 24px;
  --carousel-button-svg-height: 24px;
}

.fancybox__toolbar {
  display: flex;
  border-bottom: 1px solid #e5e7eb;
  padding: 16px !important;

  @media (min-width: 768px) {
    padding: 24px !important;
  }
}

.fancybox__toolbar__items--left {
  &:before {
    content: '';
    background-image: var(--logo--residence);
    background-size: 128px;
    background-repeat: no-repeat;
    width: 128px;
    height: 50px;
  }
}

.fancybox__counter {
  line-height: 56px !important;
  padding: 0 16px !important;
}

.fancybox__thumbs {
  padding: 16px !important;
}

.fancybox__thumbs .carousel__slide {
  margin: 0 8px !important;
  padding: 8px !important;
}

/**
* Thumbnails
*/

.carousel__slide.has-thumb {
  padding: 5px;
  margin: 16px 8px;
  border: 2px solid transparent;
  border-radius: 12px;
}

.is-nav-selected .fancybox__thumb::after {
  display: none;
}

.carousel__slide.has-thumb:hover,
.carousel__slide.has-thumb.is-nav-selected {
  border-color: var(--color--primary);
  border-width: 2px;
}

.carousel__slide.has-thumb.has-video {
  --fancybox-thumbs-ratio: 16 / 9;
}

.carousel__slide.has-thumb.has-video .fancybox__thumb::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  bottom: 0;
  background-position: center center;
  background-size: 60% 60%;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 24.38"><path d="M35.46 3.81A4.58 4.36 0 0032.24.75C29.37 0 17.93 0 17.93 0S6.48 0 3.63.72c-1.54.4-2.8 1.6-3.23 3.1-.75 2.72-.75 8.37-.75 8.37s0 5.68.75 8.37a4.58 4.36 0 003.22 3.07c2.9.75 14.3.75 14.3.75s11.45 0 14.32-.72a4.58 4.36 0 003.22-3.07c.75-2.72.75-8.37.75-8.37s.03-5.68-.75-8.4zm0 0" fill="white"/><path d="M14.29 17.4l9.51-5.21-9.51-5.22zm0 0" fill="black"/></svg>');
}

.fancybox__caption {
  color: var(--color--text);
}

.fancybox__button--zoom,
.fancybox__button--slideshow,
.fancybox__button--fullscreen,
.fancybox__button--thumbs {
  display: none !important;
}

.fancybox__container,
.fancybox__content {
  :focus {
    outline: 2px solid var(--color--primary) !important;
  }
}

#form--tour {
  &.fancybox__content {
    padding: 0;
  }
}

.fancybox__slide.has-inline {
  padding: 40px 0 !important;

  @media (min-width: 1024px) {
    padding: 80px 0 !important;
  }

  .fancybox__content > .carousel__button.is-close {
    top: -20px;
    right: 20px;
  }
}

.fancybox__slide.has-image {
  padding-top: 120px !important;

  @media (min-width: 768px) {
    padding-top: 136px !important;
  }
}
