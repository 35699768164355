/* ==========================================================================
   #STANDARD BANNER
   ========================================================================== */


.c-standard-banner {
  width: 100%;
  position: relative;
  text-align: left;
  padding: 2rem 0;
  margin: -1px auto 0 auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @include bp-medium {
    padding: 4rem 0;
  }

  &.u-text-protection--full:before {
    background: var(--color--primary);
  }

  .c-standard-content {
    position: relative;
    z-index: 2;
    max-width: 45rem;
   }

  .c-standard-banner--image {
    margin-bottom: 2rem;
    max-width: 250px;
    
    @include bp-large {
      max-width: 100%;
      margin-top: 2rem;
      float: right;
      width: 35%;
    }
  }

  .c-standard-banner--content {

    @include bp-large {
      float: left;
      width: 60%;
    }

    .c-btn {
      padding: 0 4rem 0 4rem;
    }
  }

  h1 {
    color: #222;
    line-height: 3rem;
    margin-bottom: 1rem;
    font-weight:normal;
  }

  p {
    margin-bottom: 0;
    color: #222;

    @include bp-large {
      font-size: 1.2rem;
    }
  }

  .c-btn {
    margin-top: 2rem;
  }
}

// In-Page

.c-standard-banner.inpage {
  background: var(--color--secondary);
  text-align: center;
  color: #fff;

  ul, ol {
    list-style-position: inside;
  }

  .o-container {
    max-width: 40em;
  }

  &.quote {
    padding: 4rem 0;

    @include bp-large {
      padding: 6rem 0;
    }

    .o-container {
      max-width: 50em;
      text-align: left;
    }
  }

  h1, h2, h3 {
    color: #fff;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    line-height: 2rem;
  }

  p {
    margin-bottom: 0.8rem;
    color: #fff;
    font-size: 1rem;

    &.quote {
      font-size: 1.25rem;
    }

    &.author {
      margin-top: 2rem;
    }
  }

  .c-btn {
    margin-top: 2rem;
    border: 2px solid rgba(0,0,0,0.0);
  }
}

// Blue

.c-standard-banner.blue {
  background-color: var(--color--primary);
 
}


// White

.c-standard-banner.white {
  background-color: #fff;
  color: #333;

  h1, h2, h3, p {
    color: #fff;
  }
}
