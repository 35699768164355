/* ==========================================================================
   #LISTS
   ========================================================================== */

// Shared

.c-list {
  @include clearfix();
  margin: 0;
  padding: 0;
  list-style: none;

  // No decoration

  &.c-list--no-dec {
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  // Icons

  &.c-list--has-icons {
    li {
      position: relative;
      padding-left: 40px;
      line-height: 1.6;
      margin-bottom: 8px;
    }
    .fal {
      position: absolute;
      top: 6px;
      left: 0;

      + a {
        display: inline-block;
        word-wrap: break-word;
      }
    }
  }

  &.communities {
    columns: 3;
    margin-top: 3rem;

    li {
      margin-bottom: 1rem;
    }
  }
}

// Icons Inline

.c-list--icons {
  list-style: none;
  margin-left: 0;

  li {
    display: inline;

    &::before {
      vertical-align: middle;
    }
  }

  &.c-list--icons--24 li:before {
    font-size: 24px;
  }
}

// Checklist

.c-list--checklist {
  li {
    &:before {
      font-family: 'icomoon' !important;
      color: var(--color--primary);
    }
  }
}

.c-list--numberlist {
  padding: 0;
  margin: 0 0 2rem 0;
  counter-reset: counter;

  li {
    position: relative;

    list-style-type: none;
    margin-bottom: 1rem;
    padding-left: 45px;

    &:before {
      top: 0;
      left: 0;
      counter-increment: counter;
      content: counter(counter);
      background: var(--color--primary);
      color: #fff;
      height: 30px;
      width: 30px;
      display: block;
      position: absolute;
      text-align: center;
      line-height: 26px;
      font-size: 1.1rem;
      border-radius: 50px;
    }
  }
}

// Index Page

.c-list.c-list--index {
  margin-bottom: var(--gutter);
  border: 1px solid #ddd;
  border-radius: 16px;
  transition: box-shadow ease-in-out 200ms;

  &:hover {
    @include shadow(3);
  }

  li {
    a {
      display: block;
      padding: 16px;
      font-size: 18px;
      border-bottom: 1px solid #ddd;
      color: #333;
      font-family: var(--font--body);
      text-decoration: none !important;

      &:hover {
        background: #f7f7f7;
        color: #444;
      }
    }

    &:first-child a {
      border-radius: 16px 16px 0 0;
    }

    &:last-child a {
      border-bottom: none;
      border-radius: 0 0 16px 16px;
    }
  }

  &.c-list--lonely li a {
    border-radius: 16px;
  }
}

// Separated

.c-list--separated,
.list--block {
  margin: 0;
  padding: 0;
  list-style: none;

  li:not(:last-child) {
    border-bottom: 1px solid var(--color--grey--light);
    margin-bottom: 12px;
    padding-bottom: 12px;
  }

  a {
    text-decoration: none;
    color: #444;

    &:hover {
      @include t-link__hover;
    }
  }
}

// Minor

.c-list--minor {
  font-size: 14px;
  margin-bottom: 8px;

  li {
    margin-bottom: 4px;
  }
}

// External

.c-list--external {
  font-size: 16px;

  li {
    padding-left: 38px;

    a {
      display: block;
      margin: 0 0 10px 0;
      color: var(--color--text);
      text-decoration: none;

      &:hover {
        color: #2e5797 !important;
      }

      span {
        font-size: 12px;
        display: inline-block;
        margin-left: 8px;
        color: #2e5797;
      }
    }
  }
}

// Inline Block / Horizontal

.c-list--inline-b,
.list--horizontal {
  li {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  @include bp-large {
    &.u-ta--r--ml {
      li {
        margin-right: 0;
        margin-left: 24px;
      }
    }
  }
}

// Bullet Custom

.c-list--bullet-custom {
  @include t-list--bullet-custom;
}

// Icon

.c-list--icon {
  padding-left: 24px;

  li {
    position: relative;
    margin-bottom: 4px;
  }

  .c-icon {
    position: absolute;
    top: 4px;
    left: -24px;
    color: var(--color--grey--dark);
  }
}

// Icon - Centred

.u-ta--c {
  .c-list--icon {
    .c-icon {
      position: relative;
      top: 2px;
      left: -4px;
    }
  }
}

// Margin bottom

.c-list--mb--12 {
  li {
    margin-bottom: 12px;
  }
}

// Counter

.c-list--counter {
  list-style: none;
  counter-reset: counter;
  margin-left: 0;
  padding-left: 0;

  li {
    counter-increment: counter;
    position: relative;
    padding-left: 32px;

    &:before {
      @include t-heading--h4;
      content: counter(counter) '. ';
      position: absolute;
      top: 4px;
      left: 0;
    }
  }
}

// Inherited

// Unstyled

.list--unstyled {
  list-style: none;
  padding: 0;
  margin-left: 0;
}

// Horizontal

.list--horizontal {
  margin-left: 0;

  &.list--separated {
    .fal {
      margin-left: 16px;
    }
  }
}

// Info List w/ icons

.list--info {
  list-style: none;
  margin-left: 0;

  li {
    margin-bottom: 6.25px;

    .fal {
      margin-right: 12px;
    }
  }
}

// Description Lists

dl {
  &.c-list--horizontal {
    dt,
    dd {
      display: inline;
    }

    dd:after {
      content: '';
      display: block;
    }
  }
}

// Cards

ul.row {
  list-style: none;
}

// Flex

.c-list--flex {
  display: flex;
  margin-bottom: 0 !important;

  &:last-child {
    margin-bottom: 16px !important;
  }

  li:last-child {
    margin-left: auto;
  }
}

// Flex - m

.c-list--flex--m {
  margin-bottom: 8px !important;

  &:last-child {
    margin-bottom: 24px !important;
  }

  @include bp-medium {
    display: flex;

    &:last-child {
      margin-bottom: 0 !important;
    }

    li:first-child {
      width: 82px;
      flex-shrink: 0;
    }
  }
}

// Horizontal icons

.c-list--horizontal--has-icons {
  display: flex;

  li {
    flex-shrink: 0;

    a {
      display: flex;
      flex-direction: column;
      text-align: center;
      color: var(--color--text);
      padding: 12px 30px;

      .fal {
        font-size: 24px;
        margin-bottom: 4px;
      }

      &:hover {
        text-decoration: none !important;
        background-color: var(--color--primary--lightest);
      }

      &:focus {
        outline-offset: -8px;
      }
    }
  }
}
