/* ==========================================================================
Components
  > Dashboard
========================================================================== */

.c-dashboard {

  .col {
    margin-left: 0 !important;
  }

  .c-card {

    @include bp-medium {
      // min-height: 300px;
      display: flex;
      flex-direction: column;
      height: 100%;

      &:hover {
        @include shadow(4);
        transition: box-shadow 200ms linear;
      }

      .c-card__content {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .c-card__body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
    }

    .c-card__body h3 {
      font-size: 20px;
      text-align: center;
      margin-top: 24px;
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 16px;
      text-align: center;
    }

    dl {
      margin: 0;
      line-height: 1.8;
    }

    .c-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      width: 96px;
      height: 96px;
      border-radius: 50%;
      margin: 8px auto 28px auto;
      color: var(--color--primary);
      background: var(--color--tertiary--grey);
      text-align: center;
    }

    &.c-card--has--action {

      &:hover {
        .c-icon {
          transition: var(--global--transition);
          color: #fff;
          background: var(--color--primary);
        }
      }
    }

    a {
      width: 96px;
      margin: 0 auto;

      &:hover {
        text-decoration: none;
      }
    }

    .c-btn {
      width: 100%;
      margin-top: 8px;
      color: var(--color--primary);
    }
  }
}
