/* ==========================================================================
   #HIDE
   ========================================================================== */

/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.u-hidden-visually,
.sr-only,
.h-visuallyHidden {
  @include hidden-visually();
}

/**
 * Hide visually and from screen readers.
 */

.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;

  @include bp-medium {
    display: block;
  }
}

// @max s

@include bp-max-small {
  .u-hide--max--s {
    display: none;
  }
}

// @max m

@include bp-max-medium {
  .u-hide--max--m {
    display: none;
  }
}

// @s

@include bp-small {
  .u-hide--s {
    display: none;
  }
}

// @sm

@include bp-small-medium {
  .u-hide--sm {
    display: none;
  }
}

// @m

@include bp-medium {
  .u-hide--m {
    display: none;
  }
}

// @max l

@include bp-max-large {
  .u-hide--max--l {
    display: none;
  }
}

// @l

@include bp-large {
  .u-hide--l {
    display: none;
  }
}

.u-hide {
  display: none;
}
