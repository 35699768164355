/* ==========================================================================
Utilities
  > Backgrounds
========================================================================== */

// ============ Colours

// Bg (Primary)

.u-bg-color--primary {
  background-color: var(--color--primary) !important;

  .c-widget__heading {
    color: #fff;
  }
}

.u-bg-color--primary--lightest {
  background-color: var(--color--primary--lightest) !important;
}

// Bg Colours (Secondary)

.u-bg-color--secondary {
  background-color: var(--color--secondary) !important;
}

// Bg Colours (Tertiary)

.u-bg-color--tertiary {
  background-color: var(--color--tertiary) !important;
}

// White

.u-bg--white,
.u-bg-color--white {
  background-color: #fff;
}

// Bg (Text)

.u-bg-color--text {
  background-color: var(--color--text) !important;

  h3,
  p,
  ul {
    color: #fff;
  }
}

// Stretch

.u-bg-color--stretch {
  position: relative;

  &:before,
  &:after {
    content: '';
    width: 3000px;
    height: 100%;
    position: absolute;
    top: 0;
  }

  &:before {
    left: -3000px;
  }

  &:after {
    right: -3000px;
  }

  &.u-bg-color--primary--lightest {
    &:before,
    &:after {
      background-color: var(--color--primary--lightest) !important;
    }
  }

  &.u-bg-color--text {
    &:before,
    &:after {
      background-color: var(--color--text) !important;
    }
  }
}

// ============ Fit to Container

// Bg Images - Cover

.has--bg--img {
  @include bp-small {
    @include t-bg--img;
    padding: var(--padding--wrapper);
  }

  @include bp-max-small {
    background-image: none !important;
  }
}
