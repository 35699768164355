/* ==========================================================================
Utilities
  > Size
========================================================================== */

// Scale

@include bp-max-medium {
  .u-img-scale-50-p--max-m {
    transform: scale(0.5);
  }
}

.u-img-full {
  width: 100%;
  height: auto;
}

// Font Size

.u-copy--sm {
  font-size: 0.875rem;
}

.u-copy-lg {
  font-size: 1.2rem;
}

.u-copy-huge {
  display: block;
  font-size: 42px;
  line-height: 64px;
  color: var(--color--primary);
  font-weight: 500;
  margin: 0;
}
