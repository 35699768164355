/* ==========================================================================
Components
  > Testimonials
========================================================================== */

.c-testimonial {
  max-width: 720px;
  margin: 0 auto;

  @include bp-xlarge {
    max-width: none;
  }

  .c-testimonial__media {
    display: flex;
    margin: 0 auto;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;

    img {
      @include object-fit--cover;
    }

    @include bp-small-medium {
      width: 156px;
      height: 156px;
    }

    @include bp-medium {
      width: 192px;
      height: 192px;
    }

    @include bp-xlarge {
      margin-top: 66px;
    }
  }

  .c-heading--major {
    padding-top: 62px;

    &:before,
    &:after {
      font-family: sans-serif;
      font-size: 64px;
      line-height: 1;
      position: absolute;

      @include bp-medium {
        font-size: 80px;
      }
    }

    &:before {
      content: '"';
      transform: translateY(-40px);

      transform-origin: center;

      @include bp-medium {
        transform: translate(0, -64px);
      }

      @include bp-xlarge {
        transform: translate(-56px, -32px);
      }
    }

    &:after {
      @include bp-medium {
        content: '"';
        transform: translate(24px, 40px);
      }
    }
  }

  p {
    margin: 24px 0 0 0;
    color: var(--color--primary);
  }
}

.u-bg-color--primary {
  .c-testimonial {
    h3,
    p {
      color: #fff;
    }
  }

  .carousel
    .navigation
    .slide-dot
    .slide-dot-button[aria-current='true']::after {
    background-color: #fff;
  }
}

// 2 col - LR

.c-article--main.l8 {
  .c-testimonial {
    .xl7 {
      @include bp-large {
        padding-left: 56px;
      }
      @include bp-xlarge {
        width: 75%;
      }
    }
  }

  @include bp-xlarge {
    .c-testimonial__media {
      width: 128px;
      height: 128px;
    }
  }

  [class*=' u-bg-color--'] {
    .c-testimonial {
      @include bp-large {
        padding: 0 24px 0 80px;

        .xl7 {
          padding-left: 14px;
        }
      }

      @include bp-xlarge {
        padding: 0;

        .xl7 {
          width: 66.6666%;
        }
      }

      @include bp-xlarge {
        .xl3 {
          margin-right: 40px;
        }
      }
    }
  }
}
