/* ==========================================================================
   #HERO BANNER
   ========================================================================== */

.c-hero-feature {
  .c-hero-banner {
    text-align: left;

    .c-hero-content {
      max-width: 416px;
      margin: 0;
    }
  }
}

.c-hero-banner {
  @include t-bg--img;
  display: flex;
  background-color: rgb(30, 55, 109);
  min-height: 320px;
  margin: 0 auto 0 auto;
  width: 100%;
  padding: var(--gutter);
  position: relative;
  overflow: hidden;
  text-align: center;

  @include bp-medium {
    min-height: 400px;
    padding: 48px;
  }

  &.no-content {
    padding: 0;

    img {
      width: 100%;
    }

    &:before {
      display: none;
    }
  }

  // No Image

  &.c-banner--no-image {
    &:before {
      display: none;
    }
  }

  &.u-bg-color--tertiary--grey {
    @include t-hero--light;
  }

  h1 {
    color: #fff;
    line-height: 3rem;
  }

  h2 {
    color: #fff;
    font-size: 30px;
    line-height: 38px;
    font-weight: 700;
    margin-bottom: 22px;
  }

  .c-hero-content {
    align-self: center;
    margin: auto;
    width: 100%;
    max-width: 560px;
    position: relative;
    z-index: 2;

    // Left align bullets

    &.u-ta--c {
      ul {
        text-align: left;
        transform: translateX(28%);
      }
    }

    // Content - Left

    &.c-hero-content--l {
      margin: 0;
      text-align: left;
    }

    .c-hero-btns {
      a {
        @include t-btn--outline;
        @include t-btn--outline--light;

        &:focus {
          outline-color: #fff;
        }
      }
    }

    p,
    ul,
    ol {
      color: #fff;
      font-size: 18px;
      line-height: 28px;
    }
  }
}

.c-hero-feature-left .u-text-protection--full:before {
  background: var(--color--primary--navy);
}

@media (min-width: 375px) and (max-width: 767px) {
  .c-hero-banner--alt {
    padding: 120px var(--gutter--alt) 53px var(--gutter--alt);
  }
}
// Hero Feature

.c-hero-feature {
  width: calc(100% + var(--gutter) * 2);
  margin: 0 calc(var(--gutter) * -1);

  @include bp-max-medium {
    .c-hero-feature-left {
      margin-bottom: var(--gutter);
    }
  }

  @include bp-large {
    .c-hero-banner {
      min-height: 480px;
    }
  }

  .c-hero-feature-right {
    padding: 0 var(--gutter);

    @media (min-width: 375px) and (max-width: 767px) {
      padding: 0 var(--gutter--alt);
    }
  }

  @include bp-medium {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 0;

    .c-hero-feature-left {
      flex: 0 0 65.9375%;
      margin-right: var(--gutter);
    }

    .c-hero-feature-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 0 0 31.875%;
      padding: 0;

      .c-hero-feature-right--pod {
        flex: 1;
      }
    }
  }

  .c-hero-feature-right--pod {
    @include t-bg--img;
    min-height: 15rem;
    position: relative;

    @include bp-medium {
      min-height: 0;
      margin-top: 0;
    }

    a {
      width: 100%;
      padding: 16px 28px 20px 28px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      background: rgba(40, 50, 72, 0.82);
      z-index: 2;
      color: #fff;
    }
  }

  .c-hero-content {
    align-self: flex-end;
  }
}

.c-hero-feature-right {
  .c-hero-feature-right--pod:first-child {
    margin-bottom: var(--gutter);
  }
}
