/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.btn,
.c-btn {
  @include t-btn;
}

// Secondary

.c-btn--secondary {
  background: var(--color--secondary);
}

// White

.c-btn--white {
  background: #fff;
  color: var(--color--text);

  &:hover {
    background: var(--color--primary);
  }
}

// Outline

.c-btn--outline {
  @include t-btn--outline;

  // Outline - Light

  &.c-btn--outline--light {
    @include t-btn--outline--light;
  }
}

.c-btns {
  .c-btn {
    width: 100%;
    margin: 0.5rem 0;

    @include bp-small-medium {
      width: auto;
      margin: 0.5rem;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

// Inline

.c-btns--inline {
  .c-btn {
    margin-right: 8px;
  }
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding: 0 16px;
}

/* Width variants
   ========================================================================== */

.c-btn--full {
  width: 100%;
}

// Footer Button

.c-footer__btn {
  border-top: 1px solid var(--color--grey--light);
  padding-top: 3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

// Arrow Button

.c-btn--arrow {
  color: var(--color--primary);
  background: none;
  padding: 0;
  border: 0;
  font-size: 16px;
  position: relative;
  transition: 0.3s all ease-in-out;
  font-weight: normal;
  overflow: visible;
  white-space: normal;
  text-align: left;
  height: auto;
  line-height: 1.4;
  overflow-wrap: break-word;

  span {
    margin-left: 4px;
    transition: var(--global--transition);
  }

  &:hover {
    background: transparent;
    color: var(--color--secondary);
    border: none;

    span {
      color: var(--color--secondary);
      margin-left: 8px;
    }
  }
}

// Extend

.c-btn--extend {
  width: 100%;
  margin: 0;
  display: block;
}

// No Case

.c-btn--case--none {
  text-transform: none;
}

// Weight - 400

.c-btn--weight--400 {
  font-weight: 400;
}

// Meta

.c-btn__meta {
  display: inline-block;
  height: 100%;
  border-left: 1px solid rgba(255, 255, 255, 0.33);
  padding-left: 12px;
  margin-left: 12px;
}

// Icon

.c-btn__icon {
  display: inline-block;
  margin-right: 4px;
}

// Disabled

.c-btn:disabled {
  opacity: 0.48;
  pointer-events: none;
}

.c-filter__btn:disabled {
  opacity: 0;
  cursor: auto;
}

// Close

[class^='c-btn--close'] {
  @include t-reset--btn;
  width: var(--tap-target--size);
  height: var(--tap-target--size);
  line-height: var(--tap-target--size);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  font-size: 24px;

  &:focus {
    color: #fff;
    background: var(--color--primary);
  }
}

// Search

.c-search-btn {
  @include t-reset--btn;
  margin-left: auto;

  @include bp-medium-large {
    justify-content: center;
    width: var(--header--height);
    border-left: 1px solid var(--color--grey--light);
  }

  @include bp-xlarge {
    position: absolute;
    top: 0;
    right: 0;
  }

  span {
    font-size: 18px;
    color: #222;
    cursor: pointer;
    display: block;
    width: 24px;
    height: 24px;
    line-height: 24px;

    @include bp-large {
      font-size: 20px;
    }
  }

  &.active {
    span {
      color: var(--color--grey);
    }
  }

  &:focus {
    outline: none;

    span {
      color: var(--color--primary);
      outline: 2px solid var(--color--primary);
      outline-offset: 4px;
      border-radius: 100%;

      @include bp-medium-large {
        outline-offset: 16px;
      }
    }
  }
}

// Header - Close

.c-header {
  .c-btn--close__search {
    @include t-btn--header--base;
    width: var(--header--height);
    border-left: 1px solid var(--color--grey--light);

    span {
      width: 24px;
      height: 24px;
    }

    &:focus {
      outline: none;
      background: #fff;

      span {
        color: var(--color--primary);
        outline: 2px solid var(--color--primary);
        outline-offset: 4px;
        border-radius: 100%;

        @include bp-medium-large {
          outline-offset: 16px;
        }
      }
    }
  }
}

.scroll-down,
.scroll-up {
  .c-btn--close__search {
    height: var(--header--height--sticky);
  }
}

// Label - Close

.c-btn--label-close {
  @include t-reset--btn;
  background-color: #ebebeb;
  padding-left: 2px;
  padding-right: 2px;
  border-left: 2px solid #fff;
  text-align: center;
}

// Trigger

.c-btn--trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  width: 80px;
  height: var(--header--height);

  @include bp-large {
    height: 80px;
  }

  .c-btn__icon {
    font-size: 24px;
    margin-right: 8px;
  }
}

// Filters

.c-top-filters {
  button {
    width: 100%;
  }
}

// Min 256

.c-btn--min--256 {
  min-width: 256px;
}

// Download

.c-btn--download {
  color: var(--color--text);
  margin-top: 24px;

  .far fa-chevron-down {
    font-size: 14px;
  }

  @include bp-medium {
    margin-left: auto;
    margin-bottom: -40px;
    padding-left: 32px;
    background: #fff;
  }
}

// Plain

.c-btn--plain {
  font-size: 16px;
  background: transparent;
  color: var(--color--primary);
  padding: 0;
  height: auto;
  line-height: normal;
  white-space: normal;

  &:hover {
    background: transparent;
    color: var(--color--primary) !important;
    text-decoration: underline !important;
  }

  &:focus {
    outline-offset: 4px;
  }
}

.u-bg-color--text {
  .c-btn:focus {
    outline-color: rgba(255, 255, 255, 0.86);
  }
}

// Remove defaults

.c-btn--remove-default {
  @include t-btn--remove-default;
}

// Absolute

.c-btn--absolute {
  position: absolute;
  top: 0;
  right: 14px;
}

// Close - Mega

.close-button--mega {
  .fal {
    position: relative;
    top: 2px;
    margin-left: 4px;
  }

  &:focus {
    outline-color: var(--color--primary);
  }
}

// Container

.c-btn--container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .c-btn {
    margin: 10px;
  }
}