/* ==========================================================================
Tools
  > Spacing
========================================================================== */

// Let's output some useful margin and padding options @ 8pt increments

@for $i from 0 through 18 {
  // Margin

  .u-mt--#{$i} {
    margin-top: #{$i * 8}px !important;
  }

  .u-mr--#{$i} {
    margin-right: #{$i * 8}px !important;
  }

  .u-mb--#{$i} {
    margin-bottom: #{$i * 8}px !important;
  }

  .u-ml--#{$i} {
    margin-left: #{$i * 8}px !important;
  }

  // Padding

  .u-p--#{$i} {
    padding: #{$i * 8}px !important;
  }

  .u-pt--#{$i} {
    padding-top: #{$i * 8}px !important;
  }

  .u-pr--#{$i} {
    padding-right: #{$i * 8}px !important;
  }

  .u-pb--#{$i} {
    padding-bottom: #{$i * 8}px !important;
  }

  .u-pl--#{$i} {
    padding-left: #{$i * 8}px !important;
  }
}

// Negative

@for $i from 0 through 8 {
  // Margin

  .u-mt--#{$i}--neg {
    margin-top: -#{$i * 8}px !important;
  }

  .u-mb--#{$i}--neg {
    margin-bottom: -#{$i * 8}px !important;
  }

  .u-ml--#{$i}--neg {
    margin-left: -#{$i * 8}px !important;
  }
}

.u-mt--38--neg {
  margin-top: 0;

  @include bp-largest {
    margin-top: 80px;
  }

  @media (min-width: 1920px) and (min-height: 1320px) {
    margin-top: -140px;
  }
}
