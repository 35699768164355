/* ==========================================================================
   #Text Widget
   ========================================================================== */

.c-text-widget {
  width: 100%;
  border: 1px solid #ccc;
  padding: 32px;
  border-radius: var(--global--radius);
  border-left: 8px solid var(--color--primary);
  margin-bottom: var(--gutter);

  &:last-child {
    margin-bottom: 0;
  }

  &.orange {
    border-left: 8px solid var(--color--secondary);

    .c-btn {
      background: var(--color--secondary);
      border-color: var(--color--secondary);
    }
  }

  &.c-text-widget--plain {
    border-left: 1px solid #ccc;
  }

  h4 {
    color: #444;
  }
}
