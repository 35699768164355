/* ==========================================================================
   #GRID
   =========================================================================== */

// Aside - RR - Page Listing < @l

.o-sidebar--r {
  .c-widget--page-listing {
    margin-bottom: 0;

    .col {
      @include bp-small-medium {
        float: left;
        width: 50% !important;
      }
      @include bp-medium-large {
        width: 25% !important;
        clear: none !important;
      }
    }
  }
}

// Aside - RR - Reset Row & Col @l

@include bp-large {
  .o-sidebar--r {
    .row {
      margin: 0;

      .col {
        float: none !important;
        width: 100% !important;
        margin: 0 !important;
        padding: 0;
      }
    }
  }
}

.o-container .row.smlgutter {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: 0;
}

.o-container .row.lggutter {
  margin-left: -2rem;
  margin-right: -2rem;
  margin-bottom: 0;
}

.row {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3em;
}

.row:after {
  content: '';
  display: table;
  clear: both;
}

.row .col {
  float: left;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: var(--gutter);
}

@include bp-large {
  .o-row--col--mb--l {
    .col {
      margin-bottom: var(--gutter);
    }
  }
}

.row.no-margin,
.row.no-margin > .col {
  margin-bottom: 0;
}

.row > .col {
  padding: 0 calc(var(--gutter) / 2);
}

// Filters

@include bp-xlarge {
  .c-top-filters {
    .row {
      .col {
        &.xl3--nested {
          width: 37.5%;
        }

        &.xl2--nested {
          width: 25%;
        }
      }
    }
  }
}

.row.smlgutter > .col {
  padding: 0 0.5rem;
}

.row.lggutter > .col {
  padding: 0 2rem;
}

.row .col[class*='push-'],
.row .col[class*='pull-'] {
  position: relative;
}

// @xs (>=0)

.row .col.xs1 {
  width: 8.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.xs2 {
  width: 16.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.xs3 {
  width: 25%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.xs4 {
  width: 33.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.xs5 {
  width: 41.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.xs6 {
  width: 50%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.xs7 {
  width: 58.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.xs8 {
  width: 66.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.xs9 {
  width: 75%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.xs10 {
  width: 83.3333333333%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.xs11 {
  width: 91.6666666667%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.xs12 {
  width: 100%;
  margin-left: auto;
  left: auto;
  right: auto;
}

.row .col.offset-xs1 {
  margin-left: 8.3333333333%;
}

.row .col.pull-xs1 {
  right: 8.3333333333%;
}

.row .col.push-xs1 {
  left: 8.3333333333%;
}

.row .col.offset-xs2 {
  margin-left: 16.6666666667%;
}

.row .col.pull-xs2 {
  right: 16.6666666667%;
}

.row .col.push-xs2 {
  left: 16.6666666667%;
}

.row .col.offset-xs3 {
  margin-left: 25%;
}

.row .col.pull-xs3 {
  right: 25%;
}

.row .col.push-xs3 {
  left: 25%;
}

.row .col.offset-xs4 {
  margin-left: 33.3333333333%;
}

.row .col.pull-xs4 {
  right: 33.3333333333%;
}

.row .col.push-xs4 {
  left: 33.3333333333%;
}

.row .col.offset-xs5 {
  margin-left: 41.6666666667%;
}

.row .col.pull-xs5 {
  right: 41.6666666667%;
}

.row .col.push-xs5 {
  left: 41.6666666667%;
}

.row .col.offset-xs6 {
  margin-left: 50%;
}

.row .col.pull-xs6 {
  right: 50%;
}

.row .col.push-xs6 {
  left: 50%;
}

.row .col.offset-xs7 {
  margin-left: 58.3333333333%;
}

.row .col.pull-xs7 {
  right: 58.3333333333%;
}

.row .col.push-xs7 {
  left: 58.3333333333%;
}

.row .col.offset-xs8 {
  margin-left: 66.6666666667%;
}

.row .col.pull-xs8 {
  right: 66.6666666667%;
}

.row .col.push-xs8 {
  left: 66.6666666667%;
}

.row .col.offset-xs9 {
  margin-left: 75%;
}

.row .col.pull-xs9 {
  right: 75%;
}

.row .col.push-xs9 {
  left: 75%;
}

.row .col.offset-xs10 {
  margin-left: 83.3333333333%;
}

.row .col.pull-xs10 {
  right: 83.3333333333%;
}

.row .col.push-xs10 {
  left: 83.3333333333%;
}

.row .col.offset-xs11 {
  margin-left: 91.6666666667%;
}

.row .col.pull-xs11 {
  right: 91.6666666667%;
}

.row .col.push-xs11 {
  left: 91.6666666667%;
}

.row .col.offset-xs12 {
  margin-left: 100%;
}

.row .col.pull-xs12 {
  right: 100%;
}

.row .col.push-xs12 {
  left: 100%;
}

// @s (>=480)

@include bp-small {
  .row .col.s1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.s2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.s3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.s4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.s5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.s6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.s7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.s8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.s9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.s10 {
    width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.s11 {
    width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.s12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.offset-s1 {
    margin-left: 8.3333333333%;
  }
  .row .col.pull-s1 {
    right: 8.3333333333%;
  }
  .row .col.push-s1 {
    left: 8.3333333333%;
  }
  .row .col.offset-s2 {
    margin-left: 16.6666666667%;
  }
  .row .col.pull-s2 {
    right: 16.6666666667%;
  }
  .row .col.push-s2 {
    left: 16.6666666667%;
  }
  .row .col.offset-s3 {
    margin-left: 25%;
  }
  .row .col.pull-s3 {
    right: 25%;
  }
  .row .col.push-s3 {
    left: 25%;
  }
  .row .col.offset-s4 {
    margin-left: 33.3333333333%;
  }
  .row .col.pull-s4 {
    right: 33.3333333333%;
  }
  .row .col.push-s4 {
    left: 33.3333333333%;
  }
  .row .col.offset-s5 {
    margin-left: 41.6666666667%;
  }
  .row .col.pull-s5 {
    right: 41.6666666667%;
  }
  .row .col.push-s5 {
    left: 41.6666666667%;
  }
  .row .col.offset-s6 {
    margin-left: 50%;
  }
  .row .col.pull-s6 {
    right: 50%;
  }
  .row .col.push-s6 {
    left: 50%;
  }
  .row .col.offset-s7 {
    margin-left: 58.3333333333%;
  }
  .row .col.pull-s7 {
    right: 58.3333333333%;
  }
  .row .col.push-s7 {
    left: 58.3333333333%;
  }
  .row .col.offset-s8 {
    margin-left: 66.6666666667%;
  }
  .row .col.pull-s8 {
    right: 66.6666666667%;
  }
  .row .col.push-s8 {
    left: 66.6666666667%;
  }
  .row .col.offset-s9 {
    margin-left: 75%;
  }
  .row .col.pull-s9 {
    right: 75%;
  }
  .row .col.push-s9 {
    left: 75%;
  }
  .row .col.offset-s10 {
    margin-left: 83.3333333333%;
  }
  .row .col.pull-s10 {
    right: 83.3333333333%;
  }
  .row .col.push-s10 {
    left: 83.3333333333%;
  }
  .row .col.offset-s11 {
    margin-left: 91.6666666667%;
  }
  .row .col.pull-s11 {
    right: 91.6666666667%;
  }
  .row .col.push-s11 {
    left: 91.6666666667%;
  }
  .row .col.offset-s12 {
    margin-left: 100%;
  }
  .row .col.pull-s12 {
    right: 100%;
  }
  .row .col.push-s12 {
    left: 100%;
  }
}

// @sm (>=540)

@include bp-small-medium {
  .row .col.sm1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.sm2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.sm3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.sm4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.sm5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.sm6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.sm7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.sm8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.sm9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.sm10 {
    width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.sm11 {
    width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.sm12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.offset-sm1 {
    margin-left: 8.3333333333%;
  }
  .row .col.pull-sm1 {
    right: 8.3333333333%;
  }
  .row .col.push-sm1 {
    left: 8.3333333333%;
  }
  .row .col.offset-sm2 {
    margin-left: 16.6666666667%;
  }
  .row .col.pull-sm2 {
    right: 16.6666666667%;
  }
  .row .col.push-sm2 {
    left: 16.6666666667%;
  }
  .row .col.offset-sm3 {
    margin-left: 25%;
  }
  .row .col.pull-sm3 {
    right: 25%;
  }
  .row .col.push-sm3 {
    left: 25%;
  }
  .row .col.offset-sm4 {
    margin-left: 33.3333333333%;
  }
  .row .col.pull-sm4 {
    right: 33.3333333333%;
  }
  .row .col.push-sm4 {
    left: 33.3333333333%;
  }
  .row .col.offset-sm5 {
    margin-left: 41.6666666667%;
  }
  .row .col.pull-sm5 {
    right: 41.6666666667%;
  }
  .row .col.push-sm5 {
    left: 41.6666666667%;
  }
  .row .col.offset-sm6 {
    margin-left: 50%;
  }
  .row .col.pull-sm6 {
    right: 50%;
  }
  .row .col.push-sm6 {
    left: 50%;
  }
  .row .col.offset-sm7 {
    margin-left: 58.3333333333%;
  }
  .row .col.pull-sm7 {
    right: 58.3333333333%;
  }
  .row .col.push-sm7 {
    left: 58.3333333333%;
  }
  .row .col.offset-sm8 {
    margin-left: 66.6666666667%;
  }
  .row .col.pull-sm8 {
    right: 66.6666666667%;
  }
  .row .col.push-sm8 {
    left: 66.6666666667%;
  }
  .row .col.offset-sm9 {
    margin-left: 75%;
  }
  .row .col.pull-sm9 {
    right: 75%;
  }
  .row .col.push-sm9 {
    left: 75%;
  }
  .row .col.offset-sm10 {
    margin-left: 83.3333333333%;
  }
  .row .col.pull-sm10 {
    right: 83.3333333333%;
  }
  .row .col.push-sm10 {
    left: 83.3333333333%;
  }
  .row .col.offset-sm11 {
    margin-left: 91.6666666667%;
  }
  .row .col.pull-sm11 {
    right: 91.6666666667%;
  }
  .row .col.push-sm11 {
    left: 91.6666666667%;
  }
  .row .col.offset-sm12 {
    margin-left: 100%;
  }
  .row .col.pull-sm12 {
    right: 100%;
  }
  .row .col.push-sm12 {
    left: 100%;
  }
}

// @sme (>=600)

@include bp-small-medium-extended {
  .row .col.sme6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
}

// @m (>=768)

@include bp-medium {
  .row .col.m1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m10 {
    width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m11 {
    width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.m12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.offset-m1 {
    margin-left: 8.3333333333%;
  }
  .row .col.pull-m1 {
    right: 8.3333333333%;
  }
  .row .col.push-m1 {
    left: 8.3333333333%;
  }
  .row .col.offset-m2 {
    margin-left: 16.6666666667%;
  }
  .row .col.pull-m2 {
    right: 16.6666666667%;
  }
  .row .col.push-m2 {
    left: 16.6666666667%;
  }
  .row .col.offset-m3 {
    margin-left: 25%;
  }
  .row .col.pull-m3 {
    right: 25%;
  }
  .row .col.push-m3 {
    left: 25%;
  }
  .row .col.offset-m4 {
    margin-left: 33.3333333333%;
  }
  .row .col.pull-m4 {
    right: 33.3333333333%;
  }
  .row .col.push-m4 {
    left: 33.3333333333%;
  }
  .row .col.offset-m5 {
    margin-left: 41.6666666667%;
  }
  .row .col.pull-m5 {
    right: 41.6666666667%;
  }
  .row .col.push-m5 {
    left: 41.6666666667%;
  }
  .row .col.offset-m6 {
    margin-left: 50%;
  }
  .row .col.pull-m6 {
    right: 50%;
  }
  .row .col.push-m6 {
    left: 50%;
  }
  .row .col.offset-m7 {
    margin-left: 58.3333333333%;
  }
  .row .col.pull-m7 {
    right: 58.3333333333%;
  }
  .row .col.push-m7 {
    left: 58.3333333333%;
  }
  .row .col.offset-m8 {
    margin-left: 66.6666666667%;
  }
  .row .col.pull-m8 {
    right: 66.6666666667%;
  }
  .row .col.push-m8 {
    left: 66.6666666667%;
  }
  .row .col.offset-m9 {
    margin-left: 75%;
  }
  .row .col.pull-m9 {
    right: 75%;
  }
  .row .col.push-m9 {
    left: 75%;
  }
  .row .col.offset-m10 {
    margin-left: 83.3333333333%;
  }
  .row .col.pull-m10 {
    right: 83.3333333333%;
  }
  .row .col.push-m10 {
    left: 83.3333333333%;
  }
  .row .col.offset-m11 {
    margin-left: 91.6666666667%;
  }
  .row .col.pull-m11 {
    right: 91.6666666667%;
  }
  .row .col.push-m11 {
    left: 91.6666666667%;
  }
  .row .col.offset-m12 {
    margin-left: 100%;
  }
  .row .col.pull-m12 {
    right: 100%;
  }
  .row .col.push-m12 {
    left: 100%;
  }
}

// @ml (960)

@include bp-medium-large {
  .row .col.ml1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.ml2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.ml3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.ml4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.ml5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.ml6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.ml7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.ml8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.ml9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.ml10 {
    width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.ml11 {
    width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.ml12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.offset-ml1 {
    margin-left: 8.3333333333%;
  }
  .row .col.pull-ml1 {
    right: 8.3333333333%;
  }
  .row .col.push-ml1 {
    left: 8.3333333333%;
  }
  .row .col.offset-ml2 {
    margin-left: 16.6666666667%;
  }
  .row .col.pull-ml2 {
    right: 16.6666666667%;
  }
  .row .col.push-ml2 {
    left: 16.6666666667%;
  }
  .row .col.offset-ml3 {
    margin-left: 25%;
  }
  .row .col.pull-ml3 {
    right: 25%;
  }
  .row .col.push-ml3 {
    left: 25%;
  }
  .row .col.offset-ml4 {
    margin-left: 33.3333333333%;
  }
  .row .col.pull-ml4 {
    right: 33.3333333333%;
  }
  .row .col.push-ml4 {
    left: 33.3333333333%;
  }
  .row .col.offset-ml5 {
    margin-left: 41.6666666667%;
  }
  .row .col.pull-ml5 {
    right: 41.6666666667%;
  }
  .row .col.push-ml5 {
    left: 41.6666666667%;
  }
  .row .col.offset-ml6 {
    margin-left: 50%;
  }
  .row .col.pull-ml6 {
    right: 50%;
  }
  .row .col.push-ml6 {
    left: 50%;
  }
  .row .col.offset-ml7 {
    margin-left: 58.3333333333%;
  }
  .row .col.pull-ml7 {
    right: 58.3333333333%;
  }
  .row .col.push-ml7 {
    left: 58.3333333333%;
  }
  .row .col.offset-ml8 {
    margin-left: 66.6666666667%;
  }
  .row .col.pull-ml8 {
    right: 66.6666666667%;
  }
  .row .col.push-ml8 {
    left: 66.6666666667%;
  }
  .row .col.offset-ml9 {
    margin-left: 75%;
  }
  .row .col.pull-ml9 {
    right: 75%;
  }
  .row .col.push-ml9 {
    left: 75%;
  }
  .row .col.offset-ml10 {
    margin-left: 83.3333333333%;
  }
  .row .col.pull-ml10 {
    right: 83.3333333333%;
  }
  .row .col.push-ml10 {
    left: 83.3333333333%;
  }
  .row .col.offset-ml11 {
    margin-left: 91.6666666667%;
  }
  .row .col.pull-ml11 {
    right: 91.6666666667%;
  }
  .row .col.push-ml11 {
    left: 91.6666666667%;
  }
  .row .col.offset-ml12 {
    margin-left: 100%;
  }
  .row .col.pull-ml12 {
    right: 100%;
  }
  .row .col.push-ml12 {
    left: 100%;
  }
}

// @l (1024)

@include bp-large {
  .row .col.l1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;

    &.l6--adjust {
      padding-right: 50px;
    }
  }
  .row .col.l7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;

    &.l9--adjust {
      padding-right: 50px;
    }
  }
  .row .col.l10 {
    width: 83.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l11 {
    width: 91.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.l12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }
  .row .col.offset-l1 {
    margin-left: 8.3333333333%;
  }
  .row .col.pull-l1 {
    right: 8.3333333333%;
  }
  .row .col.push-l1 {
    left: 8.3333333333%;
  }
  .row .col.offset-l2 {
    margin-left: 16.6666666667%;
  }
  .row .col.pull-l2 {
    right: 16.6666666667%;
  }
  .row .col.push-l2 {
    left: 16.6666666667%;
  }
  .row .col.offset-l3 {
    margin-left: 25%;
  }
  .row .col.pull-l3 {
    right: 25%;
  }
  .row .col.push-l3 {
    left: 25%;
  }
  .row .col.offset-l4 {
    margin-left: 33.3333333333%;
  }
  .row .col.pull-l4 {
    right: 33.3333333333%;
  }
  .row .col.push-l4 {
    left: 33.3333333333%;
  }
  .row .col.offset-l5 {
    margin-left: 41.6666666667%;
  }
  .row .col.pull-l5 {
    right: 41.6666666667%;
  }
  .row .col.push-l5 {
    left: 41.6666666667%;
  }
  .row .col.offset-l6 {
    margin-left: 50%;
  }
  .row .col.pull-l6 {
    right: 50%;
  }
  .row .col.push-l6 {
    left: 50%;
  }
  .row .col.offset-l7 {
    margin-left: 58.3333333333%;
  }
  .row .col.pull-l7 {
    right: 58.3333333333%;
  }
  .row .col.push-l7 {
    left: 58.3333333333%;
  }
  .row .col.offset-l8 {
    margin-left: 66.6666666667%;
  }
  .row .col.pull-l8 {
    right: 66.6666666667%;
  }
  .row .col.push-l8 {
    left: 66.6666666667%;
  }
  .row .col.offset-l9 {
    margin-left: 75%;
  }
  .row .col.pull-l9 {
    right: 75%;
  }
  .row .col.push-l9 {
    left: 75%;
  }
  .row .col.offset-l10 {
    margin-left: 83.3333333333%;
  }
  .row .col.pull-l10 {
    right: 83.3333333333%;
  }
  .row .col.push-l10 {
    left: 83.3333333333%;
  }
  .row .col.offset-l11 {
    margin-left: 91.6666666667%;
  }
  .row .col.pull-l11 {
    right: 91.6666666667%;
  }
  .row .col.push-l11 {
    left: 91.6666666667%;
  }
  .row .col.offset-l12 {
    margin-left: 100%;
  }
  .row .col.pull-l12 {
    right: 100%;
  }
  .row .col.push-l12 {
    left: 100%;
  }
}

// @xl (1280)

@include bp-xlarge {
  .row .col.xl1 {
    width: 8.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl2 {
    width: 16.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl3 {
    width: 25%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl4 {
    width: 33.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl5 {
    width: 41.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl6 {
    width: 50%;
    margin-left: auto;
    left: auto;
    right: auto;

    &.xl6--adjust {
      padding-right: 50px;
    }
  }

  .row .col.xl7 {
    width: 58.3333333333%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl8 {
    width: 66.6666666667%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl9 {
    width: 75%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.xl12 {
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
  }

  .row .col.offset-xl1 {
    margin-left: 8.3333333333%;
  }

  .row .col.offset-xl2 {
    margin-left: 16.6666666667%;
  }
}

/* ==========================================================================
  Clear Floats
========================================================================== */

.row .col.xs6:nth-child(2n + 1) {
  clear: both;
}

@include bp-small {
  // Clear - soft

  .row .col.s6:nth-child(2n + 1) {
    clear: both;
  }
}

@include bp-small-medium {
  // Clear - soft

  .row .col.xs6.sm4:nth-child(2n + 1) {
    clear: none;
  }

  // Re-apply

  .row .col.sm6:nth-child(2n + 1) {
    clear: both;
  }
}

@include bp-medium {
  // Clear - soft

  .row .col.m6:nth-child(2n + 1) {
    clear: both;
  }

  // Reset

  .row .col.s6.m4:nth-child(2n + 1),
  .row .col.sm6.m4:nth-child(2n + 1),
  .row .col.xs12.m6:nth-child(2n + 1) {
    clear: none;
  }

  .row.u-flex--m.u-cta--align--m .col.m6.l4:nth-child(2n+1), // 3 col
  .row.u-flex--l.u-cta--align--l .col.m6.l4:nth-child(2n+1) {
    // 2 col RR
    clear: both !important;
  }
}

@include bp-medium-large {
  // Clear - soft

  .row .col.ml6:nth-child(2n + 1) {
    clear: both;
  }

  // Reset

  .row .col.s6.ml3:nth-child(2n + 1),
  .row .col.s6.ml4:nth-child(2n + 1),
  .row .col.sm6.ml3:nth-child(2n + 1),
  .row .col.sm6.ml4:nth-child(2n + 1),
  .row .col.m6.ml3:nth-child(2n + 1) {
    clear: none;
  }

  // Re-apply

  .row .col.ml3:nth-child(4n + 1),
  .row .col.ml4:nth-child(3n + 1),
  .row .col.s6.ml4:nth-child(3n + 1) {
    clear: both !important;
  }

  .row .col.xs12.ml4:nth-child(3n + 1) {
    clear: none !important;
  }
}

@include bp-large {
  // Clear - soft

  .row .col.l6:nth-child(2n + 1) {
    clear: both;
  }

  // Reset

  .row .col.m6.l3:nth-child(2n + 1),
  .row .col.m6.l4:nth-child(2n + 1),
  .row .col.ml6.l3:nth-child(2n + 1) {
    clear: none;
  }

  // Re-apply

  .row .col.l3:nth-child(4n + 1) {
    clear: both !important;
  }
}

@include bp-xlarge {
  // Clear - soft

  .row .col.xl6:nth-child(2n + 1) {
    clear: both;
  }

  // Reset

  .row .col.m6.xl3:nth-child(2n + 1),
  .row .col.ml6.xl3:nth-child(2n + 1) {
    clear: none;
  }

  .row .col.ml4.xl3:nth-child(3n + 1) {
    clear: none !important;
  }

  // Re-apply

  .row .col.xl3:nth-child(4n + 1) {
    clear: both !important;
  }

  .row .col.sm6.xl3:nth-child(4n + 1),
  .row .col.sm6.xl3:nth-child(2n + 1) {
    clear: none !important;
  }
}

// Grid

.o-grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--padding--wrapper);

  > .o-grid__item {
    flex: 0 50%;

    @include bp-small-medium {
      flex: 0 33.333%;
    }

    @include bp-medium {
      flex: 0 25%;
    }

    @include bp-large {
      flex: 0 20%;
    }

    > span {
      display: flex;
      justify-content: center;
      border: 1px solid var(--color--grey--light);
      font-size: 40px;
      border-radius: var(--global--radius);
      margin: 16px;
      padding: 16px;
      text-align: center;

      @include bp-small-medium {
        padding: 24px;
      }

      @include bp-small-medium {
        padding: 32px;
      }
    }

    p {
      text-align: center;
    }
  }
}

// Row Filled - e.g. Style Guide

.row--filled {
  position: relative;

  .col {
    position: relative;
    z-index: 1;
    padding: 40px;

    &:before {
      content: '';
      background: var(--color--grey--light);
      border-radius: var(--global--radius);
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(var(--gutter) / 2);
      right: calc(var(--gutter) / 2);
      z-index: -1;
    }
  }
}

// Row - Vertical

[class*='row--vertical--'] {
  display: flex;
}

// Row - Vertical - Centre

.row--vertical--centre {
  align-items: center;
}

// Row - Vertical - End

.row--vertical--end {
  align-items: flex-end;
}

// Horizontal Centering

/* @xs */

@include bp-xsmall {
  .centered--xs {
    margin-left: auto;
    margin-right: auto;
  }
}

/* @s */

@include bp-small {
  .centered--s {
    margin-left: auto;
    margin-right: auto;
  }
}

/* @sm */

@include bp-small-medium {
  .centered--sm {
    margin-left: auto;
    margin-right: auto;
  }
}

/* @m */

@include bp-medium {
  .centered--m {
    margin-left: auto;
    margin-right: auto;
  }
}

/* @ml */

@include bp-medium-large {
  .centered--ml {
    margin-left: auto;
    margin-right: auto;
  }
}

/* @l */

@include bp-large {
  .centered--l {
    margin-left: auto;
    margin-right: auto;
  }
}

// Horizontal - End

/* @xs */

@include bp-xsmall {
  .end--xs {
    margin-left: auto;
  }
}

/* @s */

@include bp-small {
  .end--s {
    margin-left: auto;
  }
}

/* @sm */

@include bp-small-medium {
  .end--sm {
    margin-left: auto;
  }
}

/* @m */

@include bp-medium {
  .end--m {
    margin-left: auto;
  }
}

/* @ml */

@include bp-medium-large {
  .end--ml {
    margin-left: auto;
  }
}

/* @l */

@include bp-large {
  .end--l {
    margin-left: auto;
  }
}

// Horizontal - Start

/* @xs */

@include bp-xsmall {
  .start--xs {
    margin-right: auto;
    margin-left: 0 !important;
  }
}

/* @s */

@include bp-small {
  .start--s {
    margin-left: auto;
    margin-left: 0 !important;
  }
}

/* @sm */

@include bp-small-medium {
  .start--sm {
    margin-left: auto;
    margin-left: 0 !important;
  }
}

/* @m */

@include bp-medium {
  .start--m {
    margin-right: auto;
    margin-left: 0 !important;
  }
}

/* @ml */

@include bp-medium-large {
  .start--ml {
    margin-left: auto;
    margin-left: 0 !important;
  }
}

/* @l */

@include bp-large {
  .start--l {
    margin-left: auto;
    margin-left: 0 !important;
  }
}

/*
  Article Main adjustments
    Required when included in narrower columns
*/

@media (min-width: 1024px) and (max-width: 1279px) {
  // 3 col

  .c-article--main.xl6 {
    .u-reset--col--50--l {
      display: flex;

      .ml3,
      .ml4 {
        @include t-reset--col--50;
      }
    }
  }
}

@include bp-xlarge {
  // 3 col

  .c-article--main.xl6 {
    .u-reset--col--50--xl {
      display: flex;

      .ml4 {
        @include t-reset--col--50;
      }
    }
  }
}

/* ==========================================================================
  Full Width
========================================================================== */

// May Exist

// @ml

@include bp-medium-large {
  .c-article--main.c-col--100 {
    .ml6--may-exist {
      width: 50%;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    .ml6--may-exist {
      width: 50%;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    .ml7--may-exist {
      width: 58.3333333333%;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    .ml8--may-exist {
      width: 66.6666666667%;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    .offset-ml2--may-exist {
      margin-left: 16.6666666667%;
    }

    .offset-ml3--may-exist {
      margin-left: 25%;
    }
  }
}

// @l

@include bp-large {
  .c-article--main.c-col--100 {
    .l6--may-exist {
      width: 50%;
      margin-left: auto;
      left: auto;
      right: auto;
    }

    .offset-l3--may-exist {
      margin-left: 25%;
    }
  }
}

// Widgets - centred cols

.o-wrapper--faq,
.o-wrapper--doc-list,
.o-wrapper--tabs,
.o-wrapper--lms-promo {
  .c-col--main {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

// FAQ, LMS

.o-wrapper--faq,
.o-wrapper--lms-promo {
  .c-col--main {
    max-width: calc(626px + var(--gutter));
  }
}

// Doc List

.o-wrapper--doc-list {
  .c-col--main {
    max-width: calc(736px + var(--gutter));
  }
}

// Tabs

.o-wrapper--tabs {
  .c-col--main {
    max-width: calc(844px + var(--gutter));
  }
}
