/* ==========================================================================
Vendor
  > Prism.js
========================================================================== */

@import 'settings/_s-global';
@import 'tools/_t-breakpoints';

pre[class*="language-"] {
  background: var(--color--tertiary--grey);
  border-radius: var(--global--radius);
  margin: 32px 0 64px;
  padding: 40px;
  font-size: 16px;
  text-shadow: none;

  @include bp-medium {
    font-size: 17px;
  }
}

code[class*="language-"] {
  font-family: 'Roboto', sans-serif;
  text-shadow: none;
}

/**
 * Nord Theme Originally by Arctic Ice Studio
 * https://nordtheme.com
 *
 * Ported for PrismJS by Zane Hitchcoxc (@zwhitchcox) and Gabriel Ramos (@gabrieluizramos)
 */

code[class*="language-"],
pre[class*="language-"] {
	color: #f8f8f2;
	background: none;
	font-family: "Fira Code", Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
	text-align: left;
	white-space: pre;
	word-spacing: normal;
	word-break: normal;
	word-wrap: normal;
	line-height: 1.5;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	-webkit-hyphens: none;
	-moz-hyphens: none;
	-ms-hyphens: none;
	hyphens: none;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
	background: #2E3440;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
	color: #636f88;
}

.token.punctuation {
	color: #81A1C1;
}

.namespace {
	opacity: 0.7;
}

.token.property,
.token.tag,
.token.constant,
.token.symbol,
.token.deleted {
	color: #81A1C1;
}

.token.number {
	color: #B48EAD;
}

.token.boolean {
	color: #81A1C1;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
	color: #A3BE8C;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
	color: #81A1C1;
}

.token.atrule,
.token.attr-value,
.token.function,
.token.class-name {
	color: #88C0D0;
}

.token.keyword {
	color: #81A1C1;
}

.token.regex,
.token.important {
	color: #EBCB8B;
}

.token.important,
.token.bold {
	font-weight: bold;
}

.token.italic {
	font-style: italic;
}

.token.entity {
	cursor: help;
}

.language-css .token.string {
  color: #9a6e3a;
  background: hsla(0,0%,10%,0.5);
}

.language-markup .token.url {
  color: #9a6e3a;
  background: hsla(0,0%,10%,0.32);
}