/* ==========================================================================
Components
  > Grid Items
========================================================================== */

.c-grid-items {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;

  @include bp-large {
    margin-bottom: calc(80px - var(--gutter) - 12px);
  }

  // Col, Item

  .col,
  .c-item,
  .c-item__media {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  // Link Icon

  .c-link__icon {
    @include shadow(2);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 52px;
    transform: translateY(-50%);
    z-index: 1;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
    background: #fff;

    @include bp-small-medium {
      right: 50%;
      transform: translate(50%, -50%);
      width: 64px;
      height: 64px;
    }

    @media (max-width: 374px) {
      right: 32px;
    }

    // Play

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 10px;
      border-color: transparent transparent transparent
        var(--color--primary--light);

      @include bp-small-medium {
        border-width: 10px 0 10px 18px;
      }
    }
  }

  .c-item {
    @include bp-max-small-medium {
      @include shadow(2);
      flex-direction: row;
    }
    position: relative;
    min-height: 106px;
  }

  // Media

  .c-item__media {
    @include bp-max-small-medium {
      flex: 0 0 146px;
      order: 2;
      margin-left: auto;
      height: 100%;
    }

    @media (max-width: 374px) {
      flex: 0 0 112px;
    }
    .u-aspect {
      padding-bottom: 73%;
      height: 100%;

      @include bp-small-medium {
        flex: 1 1 auto;
      }
    }
  }

  // Action

  .c-item__action {
    .c-link--cover {
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;

      @include bp-small-medium {
        &:hover .c-link__text {
          background: var(--color--primary);
        }
      }
    }

    button {
      @include t-reset--btn;
    }
  }

  // Link Text

  .c-link__text {
    font-size: 20px;
    padding: 12px;
    text-align: left;

    @include bp-small-medium {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 8px 16px 12px 16px;
      background: var(--color--text);
      width: 100%;
      color: #fff;
      min-height: 48px;
      line-height: 28px;
    }

    @include bp-medium {
      font-size: 24px;
      line-height: 32px;
      padding-top: 6px;
      padding-bottom: 8px;
    }

    @include bp-xlarge {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
