/* ==========================================================================
Components
  > Slabs
========================================================================== */

// Inherited

.slab {
  border-left: 5px solid var(--color--grey--dark);
  padding-left: 25px;
  margin-bottom: 25px;
}