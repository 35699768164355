/* ==========================================================================
Components
  > Grid - Image
========================================================================== */

@include bp-large {
  .c-article--main.l5 {
    .c-grid--image {
      .col {
        width: 50%;
      }
    }
  }
}