/* ==========================================================================
   Utilities
     > :target

     https://css-tricks.com/hash-tag-links-padding/
     https://css-tricks.com/examples/HashLinksWithPadding/
   ========================================================================== */

.u-target {
  &:target {
    &::before {
      content: '';
      display: block;
      margin-top: calc(var(--header--height) * -1 + 24px);
      height: calc(var(--header--height) + 24px);
      visibility: hidden;
    }
  }
}

.scroll-up {
  .u-target {
    &:target {
      &::before {
        margin-top: calc(var(--header--height) * -1 + 56px);
        height: calc(var(--header--height) + 56px);
      }
    }
  }
}
