/* ==========================================================================
Components
  > Islands / Islets
========================================================================== */

// Inherited

.island {
  padding: var(--gutter);
  margin-bottom: 24px;
}

.islet {
  padding: calc(var(--gutter) / 2);
  margin-bottom: 24px;
}

.island--grey,
.islet--grey {
  background: var(--color--tertiary--grey);
}

.island--orange,
.islet--orange {
  color: #fff;
  background: var(--color--secondary);
}

.island--blue,
.islet--blue {
  color: #fff;
  background: var(--color--primary);
}

.island--border,
.islet--border {
  border: 4px solid var(--color--tertiary--grey);
  background: #fff;

  &.island--border--orange,
  &.islet--border--orange {
    border-color: var(--color--secondary);
  }

  &.island--border--blue,
  &.islet--border--blue {
    border-color: var(--color--primary);
  }
}