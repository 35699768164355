/* ==========================================================================
   #Sidebar
   ========================================================================== */

// Sidebar Nav

.c-sidebar-nav--mobile {
  display: block;
  margin-top: 2rem;
}

.c-sidebar-nav {
  margin-bottom: 0rem;
  display: block;

  @include bp-large {
    margin-bottom: 3rem;
  }

  .c-sidebar-nav--btn {
    width: 100%;
    border: 1px solid var(--color--grey--medium);
    background-color: #fff;
    text-align: left;
    padding: 0 60px 0 16px;
    height: var(--tap-target--size);
    line-height: var(--tap-target--size);
    cursor: pointer;
    font-size: 20px;
    position: relative;
    display: block;

    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 60px;
      height: var(--tap-target--size);
      line-height: var(--tap-target--size);
      text-align: center;
      font-size: 12px;
    }

    @include bp-large {
      display: none;
    }

    &.active {
      .far {
        transform: rotate(180deg) translateY(50%);
      }
    }
  }

  > ul {
    border: 1px solid var(--color--grey--medium);
    border-top: 0;
    transition: opacity 0.3s ease-in-out;
    margin-left: 0;
    margin-bottom: 0;
    padding: 16px 24px;
    display: none;

    &.active {
      display: block;

      @include bp-large {
        display: block;
      }
    }

    > li {
      position: relative;
      list-style-type: none;
      padding: 4px 0;

      &.current {
        position: relative;

        button {
          display: none;
        }

        > ul {
          display: block;
        }
      }

      ul {
        margin-top: 16px;
        margin-left: 0;
        padding-left: 24px;

        li {
          list-style-type: none;
          margin-bottom: 12px;

          a {
            display: block;
            color: var(--color--text);
            opacity: 0.8;

            &:hover {
              color: var(--color--primary);
              opacity: 1;
            }
          }
        }
      }

      > a {
        display: block;
        color: #222;
        font-family: var(--font--headings);
        line-height: 32px;
        width: calc(100% - var(--tap-target--size) - 8px);

        &:hover {
          text-decoration: none;
          color: var(--color--primary);
        }
      }

      button {
        @include t-reset--btn;
        position: absolute;
        right: 0;
        top: -4px;
        font-size: 12px;
        text-decoration: none;
        height: var(--tap-target--size);
        width: var(--tap-target--size);
        line-height: var(--tap-target--size);
        text-align: center;
        cursor: pointer;

        span {
          display: block;
          font-size: 14px;
        }

        &:hover {
          color: var(--color--primary);
        }

        &:focus {
          border-radius: 100%;
          outline: 2px solid var(--color--primary);
        }
      }

      &.active button .far {
        transform: rotate(180deg);
      }

      ul {
        display: none;
        border-left: 1px solid var(--color--text);
      }

      &.active ul {
        display: block !important;
        margin-bottom: -8px;
      }
    }

    &.active {
      height: auto;
    }

    @include bp-large {
      padding: 0;
      border: none;
      display: block !important;
    }
  }

  // Content

  .c-sidebar-nav__content {
    display: none;
    padding: 1rem 0;
    border: 1px solid var(--color--grey--medium);
    border-top: none;

    &.active {
      display: block;
    }

    @include bp-large {
      display: block;
      padding: 0;
      border: none;
      border-left: 1px solid var(--color--grey--medium);
    }
  }

  // Current

  .current a {
    display: block;
    font-family: var(--font--medium);
    color: var(--color--primary);
    opacity: 1;
  }
}
