/* ==========================================================================
Components
  > Tabs
========================================================================== */

/**
  Tab button controls
*/

.tab {
  display: flex;
  position: relative;
  background: 0;
  border: 0;
  margin: 0 20px 20px 0;
  padding: 0 0 16px 0;
  color: #444;
  background-color: #fff;
  cursor: pointer;
  white-space: nowrap;

  @include bp-small-medium {
    margin-right: 40px;
  }

  &:last-child {
    margin-right: 0;
  }
}

// Scroller

.c-tab__scroller,
.c-list__scroller {
  overflow-y: hidden;
}

.c-tab__scroller {
  margin-bottom: 18px;
}

.c-list__scroller {
  width: 100%;

  .c-list {
    width: 100%;
  }
}

.c-tab__scroller__scroll-area,
.c-list__scroller__scroll-area {
  position: relative;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  transform: none;
  will-change: transform;
}

.tablist {
  display: flex;
  flex-wrap: wrap;
  flex: 1 0 auto;
  position: relative;
  overflow-y: hidden;
  margin-bottom: 12px;

  &:after {
    content: '';
    background: var(--color--text);
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 20px;
  }
}

/** Active state */
.tab {
  &:active,
  &:focus {
    outline: none;
  }
}

/** Selected state (tabpanel visible) */
.tab[aria-selected='true'] {
  opacity: 1;
  color: var(--color--primary);
  //border-bottom: 3px solid var(--color--primary);
  //margin-bottom: -2px;

  &:after {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: -1px;
    background: var(--color--primary);
  }
}

.tab[aria-selected='true']:focus {
  outline: none;
  text-decoration: none;
}

/**
  Tab panels
*/
.tabpanel {
  display: none;
  background-color: #fff;
  padding: 0;
}

.tabpanel.is-visible {
  display: block;
}

.tabpanel h2 {
  margin-top: 0;
}

// Full Bleed

.c-tab--fb {
  .c-tab__copy__hold {
    display: flex;
    justify-content: flex-end;
    order: 2;
    width: 100%;
    padding: calc(var(--gutter) * 1.28) var(--gutter) calc(var(--gutter) * 2)
      var(--gutter);
    color: #fff;
    background: var(--color--text);

    .c-tab__copy {
      width: 100%;
    }

    h2 {
      color: #fff;
    }

    @include bp-large {
      order: 1;
      max-width: 50%;
      padding: 120px 80px 80px var(--gutter);

      .c-tab__copy {
        max-width: 510px;
      }

      h2 {
        margin-bottom: 40px;
      }
    }

    @include bp-xlarge {
      padding-right: 128px;
    }
  }

  .c-tab__media {
    position: relative;
    width: 100%;
    @include fluid-aspect(3 2);

    @include bp-large {
      order: 2;
      max-width: 50%;
      min-height: 640px;
      padding-bottom: 0;
    }

    img {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
