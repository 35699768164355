/* ==========================================================================
Settings
  > Colour
========================================================================== */

:root {
  // Text

  --color--text: #163029;

  // Primary

  --color--primary: #008550;
  --color--primary--light: #00a865;
  --color--primary--lightest: #dfefe5;

  // Secondary

  --color--secondary: #1bbfce;

  // Tertiary

  --color--tertiary: #e98a68;
  --color--tertiary--grey: #ebebeb;

  // Greys

  --color--grey: #ccc;
  --color--grey--medium: #c8c8c8;
  --color--grey--dark: #949494;
  --color--grey--light: #ddd;

  // Blacks

  --color--off-black: #222;

  // Link

  --color--link: var(--color--primary);

  // Warning

  --color--red: #b90000;
}
