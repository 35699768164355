/* ==========================================================================
   #Figures
   ========================================================================== */

.c-figure {
  overflow: hidden;
  display: block;
  background-color: #fff;

  // Ratios

  @include fluid-aspect(4 3);

  &.c-figure-ratio--1-1 {
    @include fluid-aspect(1 1);
  }

  &.c-figure-ratio--3-4 {
    @include fluid-aspect(3 4);
  }

  &.c-figure-ratio--16-9 {
    @include fluid-aspect(16 9);
  }

  &.c-figure-ratio--16-10 {
    @include fluid-aspect(16 10);
  }

  &.c-figure-ratio--9-16 {
    @include fluid-aspect(9 16);
  }

  &.c-figure-ratio--13-6 {
    @include fluid-aspect(13 6);
  }

  &.c-figure-ratio--11-4 {
    @include fluid-aspect(11 4);
  }

  &.c-figure-ratio--31-10 {
    @include fluid-aspect(31 10);
  }
}

// Inherited

figure:not(.c-figure):not(.u-aspect) {
  position: relative;

  // Calendar

  &.figure--calendar {
    @include t-figure--calendar;
    @include t-figure--calendar__detail;
    position: relative !important;

    &.figure--calendar--sm {
      font-size: 26px;
      line-height: 1.3;
      max-width: 60px;

      .calendar-detail-mo,
      .calendar-detail-yr {
        font-size: 11px;
      }
    }
  }

  // Left

  &.figure--left {
    float: left;
    margin: 6.25px var(--gutter) 12.5px 0;
    max-width: 35%;
  }

  // Right

  &.figure--right {
    float: right;
    margin: 6.25px 0 12.5px var(--gutter);
    max-width: 35%;
  }

  // Small

  &.figure--sm {
    max-width: 80px !important;
  }

  // Video

  &.figure--video {
    @include fluid-aspect(16 9);
  }
}

figcaption {
  line-height: 1.25;
}