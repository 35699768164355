/* ==========================================================================
   #BREADCRUMBS
   ========================================================================== */

.c-breadcrumb {
  padding: 13px 0;
  background: #fff;
  border-bottom: 1px solid var(--color--grey--light);

  .o-container {
    display: flex;
    max-width: none;

    @include bp-medium {
      padding: 0 48px;
    }

    @include bp-xlarge {
      padding: 0 64px;
    }

    @include bp-largest {
      padding: 0 128px;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: none;

    @include bp-medium {
      display: block;
    }

    @include bp-max-large {
      &.c-breadcrumb--alt {
        display: block;

        li:not(.c-page--current),
        span {
          display: none;
        }
      }
    }

    li {
      display: inline-block;
      margin: 0 2px 0 0;
      position: relative;
      font-size: 16px;

      a {
        color: var(--color--text);
        text-decoration: none;

        &:hover {
          color: var(--color--primary);
          text-decoration: underline;
        }

        &:focus {
          outline-offset: 2px;
        }
      }
    }

    // Toolbar

    &.c-toolbar {
      flex-shrink: 0;
      display: block;
      border-top: none;
      width: auto;
      display: block;
      margin: 0 0 0 auto;
      padding: 0 0 0 32px;

      > li {
        cursor: pointer;
        padding: 0;
        margin: 0 0 0 12px;
        font-size: 0.875rem;
      }

      .fal {
        margin-left: 4px;
      }
    }
  }

  .fa-chevron-right {
    font-size: 10px;
    margin-right: 2px;
  }
}

// Print / Share

.c-print,
.c-share {
  button {
    background: 0 0;
    border: 0;
    padding: 0;
    color: #343434;
    cursor: pointer;

    &:hover {
      color: var(--color--primary);
    }

    &:focus {
      outline-offset: 2px;
    }

    .c-btn__text {
      display: none;

      @include bp-medium {
        display: inline-block;
      }
    }
  }
}

// Share

.c-share {
  > ul {
    display: none;
    position: absolute;
    top: 49px;
    left: -12px;
    margin: 0 auto;
    background: var(--color--primary);
    width: 50px;
    padding: 10px 0 4px 0;
    text-align: center;
    transition: all 0.3s ease-in-out;
    z-index: 11;

    @include bp-medium {
      left: 10px;
    }

    &:after {
      content: '';
      position: absolute;
      top: -7px;
      left: 15px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent var(--color--primary) transparent;
    }

    > li {
      text-align: center;
      padding: 0;
      width: 100%;
      display: block;

      > a {
        color: #fff;
        width: 80%;
        display: block;
        margin: 0 auto 4px;
        border-radius: 6px;

        &:hover {
          color: #fff;
          background: var(--color--primary--light);
        }
      }
    }
  }

  &.active > ul {
    display: block;
  }
}
