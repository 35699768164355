/* ==========================================================================
   #FORM
   ========================================================================== */

// Shared

input,
select,
textarea {
  @include t-form--ui;
}

.c-form {
  @include t-form--ui--alt;

  &:not(.c-form--kentico) .col {
    margin-bottom: 48px;
  }
}

button {
  font-family: var(--font--body);
}

// Textarea

textarea {
  min-height: 192px;
  padding: 16px 24px;
  line-height: 1.5;
  resize: vertical;
}

// Select

.c-dropdown {
  position: relative;

  .far {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    font-style: normal;
    color: var(--color--text);
    pointer-events: none;
  }

  // Restricted

  &.c-dropdown--restricted {
    display: flex;
    margin-bottom: 32px;

    @include bp-medium {
      margin-left: auto;
      max-width: calc(50% - var(--gutter) / 2);
    }

    @include bp-large {
      max-width: calc(66.666% - var(--gutter) / 2);
    }

    @include bp-xlarge {
      max-width: 390px;
    }
  }

  select {
    appearance: none;
    cursor: pointer;
    padding-right: 40px;

    option {
      color: #444;
    }
  }
}

select::-ms-expand {
  display: none;
}

// Checkboxes & Radios

.checkbox,
.c-checkbox,
.ktc-checkbox,
.c-radio,
.ktc-radio {
  position: relative;
  margin: 0;
  display: block;
}

.checkbox input,
.c-checkbox input,
.ktc-checkbox input,
.c-radio input,
.ktc-radio input {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0 -24px -9999px;
  padding: 0;
  outline: none;
}

.checkbox label,
.c-checkbox label,
.ktc-checkbox label,
.c-radio label,
.ktc-radio label {
  font-family: var(--font--body) !important;
  margin: 0 0 8px 0;
  position: relative;
  cursor: pointer;
  color: var(--color--text);
  display: block;
}

// Focus

.c-checkbox input:focus + label:before,
.ktc-checkbox input:focus + label:before,
.c-radio input:focus + label:before,
.ktc-radio input:focus + label:before {
  outline: 2px solid var(--color--primary);
  outline-offset: 4px;
}

.ktc-checkbox {
  input {
    @include hidden-visually;
  }

  label {
    font-size: 16px;
  }
}

.required-explanation {
  display: block;
  margin-bottom: 16px;
}

.form-field {
  margin-bottom: var(--gutter);

  label {
    font-family: var(--font--medium);
    margin-bottom: 8px;
  }
}

// 2 col

.c-form--2-col {
  @include clearfix;

  .form-field {
    padding: 0 calc(var(--gutter) / 2);

    @include bp-medium {
      float: left;
      width: 50%;

      &:nth-child(2n + 1) {
        clear: both;
      }
    }
  }
}

.required-field-red-star::after {
  content: '*';
  color: red;
}

/* Labels */

label {
  display: block;
}

.c-radio label,
.c-checkbox label,
.checkbox label,
.ktc-checkbox label,
.ktc-radio label {
  padding-left: 32px;
}

// Radio

.c-radio label::before,
.ktc-radio label::before {
  position: absolute;
  content: '';
  top: 4px;
  left: 0;
  display: block;
  border: 2px solid rgba(22, 48, 41, 0.64);
  background: #fff;
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 100%;
}

.c-radio input:checked + label::after,
.ktc-radio input:checked + label::after {
  position: absolute;
  content: '';
  top: 9px;
  left: 5px;
  display: block;
  background: var(--color--primary);
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.c-radio input:checked + label:before,
.ktc-radio input:checked + label:before {
  border-color: var(--color--primary);
}

// Checkbox

.checkbox label::before,
.c-checkbox label::before,
.ktc-checkbox label::before {
  position: absolute;
  content: '';
  top: 6px;
  left: 0;
  display: block;
  border: 2px solid rgba(22, 48, 41, 0.64);
  background: #fff;
  width: 20px;
  height: 20px;
}

.checkbox input:checked + label::after,
.c-checkbox input:checked + label::after,
.ktc-checkbox input:checked + label::after {
  font-family: 'FontAwesome';
  content: '\f00c';
  position: absolute;
  top: 2px;
  left: 4px;
  display: block;
  color: var(--color--primary);
  font-size: 12px;
}

// Link Area Checkboxes (e.g. Residence Selector in 'Live With Us' Form)

.c-link__area {
  .c-checkbox {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    label {
      padding: 0;
      width: 100%;
      height: 100%;

      &:before {
        @include shadow(1);
        border-color: #fff;
        width: 24px;
        height: 24px;
        left: auto;
        top: 16px;
        right: 16px;
      }
    }

    input:checked + label:after {
      font-size: 14px;
      top: 15px;
      left: auto;
      right: 21px;
    }

    input:checked + label:before {
      @include shadow(2);
    }

    input:focus + label:before {
      outline-offset: 0;
    }
  }
}

// Radio Boxes

.c-radio__box {
  display: inline-block;
  margin: 0 var(--gutter) / 2;
  text-align: center;

  &.is-active .c-radio__content {
    border: 1px solid var(--color--primary);
  }

  &.is-active .c-radio__content label {
    color: var(--color--primary);
  }

  .c-radio__content {
    position: relative;
    color: var(--color--primary);
    border: 1px solid #dbdbdb;
    padding: 6px 16px 8px 16px;
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0) 55%
    );
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;

    label {
      margin: 0;
      color: var(--color--primary);
      font-size: 20px;

      @include bp-medium {
        font-size: 38px;
      }
    }

    &:hover {
      border: 1px solid var(--color--primary);
    }

    @include bp-medium {
      padding: 16px 32px 22px 32px;
    }

    input[type='radio'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
      padding: 0;
      border-radius: 0;
      z-index: 5;
    }
  }
}

.c-radio__box--text {
  .c-radio__content {
    input {
      border: 0;
      border-radius: 0;
      background: 0;
      font-weight: 600;
      width: 120px;
      padding: 0;
      text-align: center;
      color: var(--color--primary);
    }

    @include bp-medium {
      padding: 19px 32px 22px 32px;
      font-size: 2rem;
    }
  }
}

// Responsive Radio Groups

.c-radio__group--responsive {
  .c-radio__box {
    margin: 0;
    float: left;
    padding-left: var(--gutter) / 2;
    padding-right: var(--gutter) / 2;
    width: 50%;

    @include bp-small {
      width: 25%;
    }
  }
}

// Radio Groups - Horizontal

@include bp-small {
  .c-radio__group--horizontal {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;

    .c-radio {
      margin-right: 16px;
      margin-bottom: 4px;

      @include bp-small-medium-extended {
        margin-right: 28px;
      }
    }
  }
}

// Fieldset

fieldset {
  margin-bottom: 0.5rem;

  h4 {
    margin-bottom: 1.5rem;
    font-weight: normal;
    color: var(--color--secondary);
  }

  legend {
    font-family: var(--font--medium);
    color: var(--color--text);
  }

  input,
  select,
  textarea {
    margin-bottom: 0.75rem;
  }

  .row {
    margin-bottom: 0;
  }
}

/* Placeholders ) */

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--color--text);
  opacity: 1;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--color--text);
  opacity: 1;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--color--text);
  opacity: 1;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: var(--color--text);
  opacity: 1;
}

.WatermarkText {
  color: var(--color--grey--medium);
}

/* Focus */

input:focus::-webkit-input-placeholder {
  opacity: 1;
}

input:focus::-moz-placeholder {
  opacity: 1;
}

input:focus:-ms-input-placeholder {
  opacity: 1;
}

input:focus:-moz-placeholder {
  opacity: 1;
}

textarea:focus::-webkit-input-placeholder {
  opacity: 1;
}

textarea:focus::-moz-placeholder {
  opacity: 1;
}

textarea:focus:-ms-input-placeholder {
  opacity: 1;
}

textarea:focus:-moz-placeholder {
  opacity: 1;
}

/* Textarea */

textarea.form-control::-moz-placeholder,
.form-group textarea.form-control::-moz-placeholder {
  line-height: inherit !important;
}

textarea.form-control:-ms-input-placeholder,
.form-group textarea.form-control:-ms-input-placeholder {
  line-height: inherit !important;
}

textarea.form-control::-webkit-input-placeholder,
.form-group textarea.form-control::-webkit-input-placeholder {
  line-height: inherit !important;
}

/* Placeholders - Search */

.c-search .form-control::-moz-placeholder,
.c-search .form-group .form-control::-moz-placeholder {
  font-size: 18px;
}

.c-search .form-control:-ms-input-placeholder,
.c-search .form-group .form-control:-ms-input-placeholder {
  font-size: 18px;
}

.c-search .form-control::-webkit-input-placeholder,
.c-search .form-group .form-control::-webkit-input-placeholder {
  font-size: 18px;
}

/* Captchas */

.CaptchaTextBox {
  text-align: center;
}

label[for*='GoogleRecaptchaV3Component'] {
  display: none;
}

// Asterisk

.c-asterisk {
  padding-left: 20px;
  position: relative;
  font-size: 0.875rem;

  &::before {
    content: '*';
    position: absolute;
    top: 0;
    left: 0;
    color: var(--color--primary);
    font-size: 1.2rem;
  }
}

// Validation
.field-validation-error {
  color: red;
}

// Article Grid - labels

.c-article--grid {
  .c-label {
    position: absolute;
    font-size: 16px;
    background: #fff;
    transform: translate(16px, -64px);
    height: 28px;
    line-height: 28px;
    padding: 0 12px;
    border-radius: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 32px);
  }
}

.c-g-map {
  position: relative;
  border: 1px solid #ddd;
  max-width: 640px;
  margin: 0 auto;

  .c-label {
    position: absolute;
    bottom: 8px;
    right: 8px;
    font-size: 14px;
    background: #fff;
    height: 24px;
    line-height: 22px;
    padding: 0 8px;
    border: 1px solid #707070;
    border-radius: 16px;

    @include bp-small {
      font-size: 16px;
      bottom: 16px;
      right: 16px;
      height: 28px;
      line-height: 26px;
      padding: 0 12px;
    }
  }
}

// Floating Labels

.c-label--float {
  @include float-label-container;
  @include float-label;
  display: block;
  width: 100%;

  select {
    appearance: none;
  }

  input,
  select,
  textarea {
    @include float-label-input;

    @include float-label-scaled {
      font-size: 18px;
      color: var(--color--text);
      top: 13px;
      left: 24px;

      @media (min-width: 375px) {
        top: 12px;
        font-size: 20px;
      }
    }
  }

  textarea {
    &:placeholder-shown:not(:focus) + * {
      top: 16px;
    }
  }

  // Large e.g. Search Overlay

  &.c-label--float--l {
    @include float-label--l;

    input {
      @include bp-medium {
        font-size: 28px;
      }

      @include float-label-scaled {
        @include bp-medium {
          font-size: 28px;
          top: 26px;
          left: 28px;
          line-height: 1.1;
        }
      }

      &:focus {
        @include shadow(2);
      }
    }
  }

  &.c-label--has-button {
    label {
      right: 100px;
    }

    input {
      width: calc(100% - 96px);
    }
  }
}

.grecaptcha-badge {
  @include hidden-visually;
}

// Filters - Top

.c-top-filters {
  background: #fff;

  .row {
    margin-bottom: 0;
  }

  .c-filter__direction {
    display: none;
  }

  @include bp-xlarge {
    .col {
      margin-bottom: 0;
    }
  }
}

// Filters - Top - Hero

.c-hero {
  .c-top-filters {
    width: 100%;
    max-width: 844px;
    margin: 32px 0 0 0;
    margin: 0; // temp
    padding: 0;
    border: none;
    box-shadow: 0 16px 40px rgba(22, 48, 41, 0.4);

    @media (max-width: 374px) {
      font-size: 18px;
    }

    .c-filter__direction {
      display: block;
      color: var(--color--text);
      margin: 8px 0 0 0;
      line-height: 30px;

      @include bp-max-medium-large {
        font-size: 18px;
      }
    }

    .o-container {
      padding: 24px 24px 16px 24px;

      .row {
        margin: 0 -8px;

        .col {
          margin: 0 0 16px;
          padding: 0 8px;

          &:last-child {
            margin: 0;
          }

          @include bp-xlarge {
            margin: 0;
          }
        }
      }

      @include bp-medium-large {
        padding-bottom: 24px;
      }
    }

    @include bp-medium-large {
      .c-heading--form {
        margin-top: -8px;
      }
    }

    // Direction

    .c-filter__direction {
      @include bp-medium-large {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(100%);
      }

      a {
        color: var(--color--text);
        border-bottom: 2px solid var(--color--text);
        padding-bottom: 2px;
      }

      @include bp-medium-large {
        padding: 24px;
        background: none;
        font-size: 20px;
      }

      @media (min-width: 960px) {
        text-align: left;
        color: #fff;

        a {
          color: #fff;
          border-bottom-color: #fff;
        }
      }
    }
  }

  // 0797_SL_RFC0003b - Search reposition >= @ml < @l

  @include bp-medium-large {
    &:not([data-article-hero__layout='100']) {
      .c-top-filters {
        position: absolute;
        bottom: 80px;
        right: var(--gutter);
        width: calc(50% - var(--gutter) * 2);
        z-index: 4;

        @media (max-height: 599px) {
          bottom: auto;
          top: calc(var(--header--height) + var(--gutter));
        }

        @media (min-width: 960px) and (min-height: 1080px) {
          bottom: 96px;
        }

        @media (min-width: 1920px) and (min-height: 1080px) {
          bottom: 128px;
        }

        @media (min-width: 1920px) and (min-height: 1320px) {
          bottom: 256px;
        }

        @media (max-width: 1279px) {
          .col.m6 {
            width: 100%;
          }
        }
      }

      // Add text protection

      .c-hero__media:after {
        content: '';
        background: linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,0.65) 100%);
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }

  // @media (min-width: 960px) and (max-width: 1279px) {

  // }

  @include bp-xlarge {
    &:not([data-article-hero__layout='100']) {
      .c-top-filters {
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
      }

       // Remove text protection

       .c-hero__media:after {
        display: none;
       }
    }
  }
}

// Layout - 100

.c-hero[data-article-hero__layout='100'] {
  .c-filter__direction {
    text-align: center;
    width: 100%;
  }
}

// No text protection

.c-hero[data-article-hero__protection='none'] {
  .c-top-filters {
    @media (min-width: 960px) and (min-height: 1160px) {
      bottom: 100px;
    }

    @media (min-width: 960px) and (min-height: 1320px) {
      bottom: 373px;
    }

    .c-filter__direction {
      position: relative;
      transform: none;
      color: var(--color--text);
      padding-bottom: 0;
      font-size: 18px;

      a {
        color: var(--color--text);
        border-color: var(--color--text);
      }
    }
  }
}

.c-form--builder {
  .c-btn {
    margin-left: 14px;
  }
}
