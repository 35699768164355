/* ==========================================================================
   Utilities
     > Fonts
   ========================================================================== */

// Inherited

  // Colour

  .h-colorRed {
    color: #e31836;
  }

  .h-colorGreen {
    color: #42be2a;
  }

  .h-colorBlue {
    color: #0080F6;
  }

  .h-colorYellow {
    color: #f9b106;
  }

  .h-colorGrey {
    color: #959595;
  }

  .h-colorGreyLight {
    color: #ccc;
  }

  .h-colorGreyLighter {
    color: #ebebeb;
  }

  .h-colorGreyLightest {
    color: #f7f7f7;
  }

  .h-colorWhite {
    color: #fff;
  }

  .h-colorBlack {
    color: #000;
  }

  // Face

  .h-fontHighlight {
    font-family: var(--font--headings)
  }

  .h-fontBase {
    font-family: var(--font--body);
  }