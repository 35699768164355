/* ==========================================================================
   Tools
     > Navigation
   ========================================================================== */

// Border

@mixin t-nav-item__border {
  content: '';
  position: absolute;
  bottom: 22px;
  left: 32px;
  right: 37px;
  height: 2px;
  background: var(--color--primary);

  @media (min-width: 1280px) and (max-height: 900px) {
    right: 4px;
  }
}
