/* ==========================================================================
Tools
  > Order
========================================================================== */

@for $i from 0 through 23 {
  .u-order--#{$i} {
    order: $i;
  }
}

// xs

@include bp-xsmall {
  @for $i from 0 through 23 {
    .u-order--xs--#{$i} {
      order: $i;
    }
  }
}

// s

@include bp-small {
  @for $i from 0 through 23 {
    .u-order--s--#{$i} {
      order: $i;
    }
  }
}

// sm

@include bp-small-medium {
  @for $i from 0 through 23 {
    .u-order--sm--#{$i} {
      order: $i;
    }
  }
}

// m

@include bp-medium {
  @for $i from 0 through 23 {
    .u-order--m--#{$i} {
      order: $i;
    }
  }
}

// m

@include bp-medium-large {
  @for $i from 0 through 23 {
    .u-order--ml--#{$i} {
      order: $i;
    }
  }
}

// l

@include bp-large {
  @for $i from 0 through 23 {
    .u-order--l--#{$i} {
      order: $i;
    }
  }
}

// xl

@include bp-xlarge {
  @for $i from 0 through 23 {
    .u-order--xl--#{$i} {
      order: $i;
    }
  }
}