/* ==========================================================================
Components
  > Sections (not <section>)
========================================================================== */

// Inherited

.section {
  clear: both;
  padding: 48px;
  background-size: cover;
  background-repeat: no-repeat;

  > :last-child > :last-child {
    margin-bottom: 0;
  }
}

  // Container

  .contained {
    max-width: 1350px;
    margin: auto;
  }