/* ==========================================================================
Utilities
  > Links
      Ref: https://www.w3.org/TR/WCAG20-TECHS/G201.html
========================================================================== */

// Link

.u-link--warning {
  position: relative;
  z-index: 1;

  // Link Text

  .u-link-warning__text {
    position: absolute;
    left: -9000px;
    width: 0;
    overflow: hidden;
  }

  // Link Text - Show

  &:hover,
  &:focus,
  &:active {
    z-index: 20;

    .u-link-warning__text {
      @include shadow(4);
      display: block;
      position: absolute;
      font-size: 16px;
      top: -40px;
      left: 0;
      width: 170px;
      padding: 8px;
      line-height: 1;
      border-radius: var(--global--radius);
      background-color: var(--color--primary);
      color: #fff;
    }
  }
}

// Primary

.u-link--primary {
  color: var(--color--primary) !important;

  &:hover {
    color: var(--color--text) !important;
  }
}
