/* ==========================================================================
Components
  > Heroes
========================================================================== */

.c-hero {
  display: flex;
  flex-direction: column;

  @include bp-medium-large {
    flex-direction: row;
  }

  // Content

  .c-hero__content {
    display: flex;
    align-items: flex-end;
    order: 2;
    padding: 32px;
    z-index: 3;

    .c-hero__pretext {
      font-size: 20px;
      letter-spacing: 0;
      margin: 0 0 16px 5px;

      @include bp-full {
        margin: 0 0 -16px 10px;
      }
    }

    h1,
    p {
      color: #fff;
    }

    h1 {
      margin: 0;
    }

    .c-label {
      position: absolute;
      top: calc(var(--header--height) + 32px);
      right: 32px;
      z-index: 2;
      font-size: 16px;
      background: #fff;
      height: 28px;
      line-height: 28px;
      padding: 0 12px;
      border-radius: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @include bp-medium-large {
        top: calc(var(--header--height) + 56px);
        right: 48px;
      }

      @include bp-full {
        right: var(--header--height);
      }
    }

    @include bp-medium {
      padding: 48px;
    }

    @include bp-xlarge {
      padding: 80px 64px;
    }
  }

  // Media

  .c-hero__media {
    @include fluid-aspect(3 2);
    position: relative;
    min-height: 234px;

    @include bp-medium-large {
      padding-bottom: 0;
    }

    img:-moz-loading {
      visibility: hidden;
    }
  }
}

/* ==========================================================================
Data attribute controls available:

  data-article-hero__size               [mini large]
  data-article-hero__layout             [50-50 50-100 100]
  data-article-hero__protection--mobile [transparency--green--max--ml]
  data-article-hero__protection         [full transparency gradient none]
  data-article-hero__aspira-a           [true false]
  data-article-hero__img-position       [top bottom]

  Size
========================================================================== */

/* ==========================================================================
  Mini
========================================================================== */

[data-article-hero__size='mini'] {
  min-height: 300px;

  @include bp-medium-large {
    min-height: 340px;

    h1 {
      font-size: 64px;
    }
  }

  @include bp-large {
    h1 {
      font-size: 64px;
    }
  }

  @include bp-xlarge {
    min-height: 400px;

    h1 {
      font-size: 72px;
    }
  }

  @include bp-full {
    min-height: 500px;

    h1 {
      font-size: 90px;
      line-height: 1;
    }
  }

  @media (min-width: 1920px) {
    min-height: 600px;

    h1 {
      font-size: 107px;
      line-height: 104px;
    }
  }
}

/* ==========================================================================
  Large
========================================================================== */

[data-article-hero__size='large'] {
  height: 100%;
  min-height: 600px;

  @include bp-medium-large {
    height: calc(100vh - var(--header--height)) !important;
  }

  .c-hero__content {
    h1 {
      margin-bottom: 32px;

      @media (max-width: 374px) {
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -1.08px;
      }

      @media (min-width: 540px) and (min-height: 860px) {
        margin-bottom: 64px;
      }
    }
  }

  .c-hero__content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .c-hero__content__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  //@media (min-width: 768px) and (min-height: 1024px) {
  @media (min-width: 540px) and (min-height: 860px) {
    height: 100vh;

    .c-hero__content {
      height: 100vh;
    }
  }

  @include bp-medium-large {
    .c-hero__content {
      height: calc(100vh - var(--header--height));
      min-height: 600px;
    }
  }

  .c-logo__symbol {
    transition: var(--global--transition);
    width: 100%;
    max-width: 128px;

    @media (min-width: 1440px) and (min-height: 1080px) {
      max-width: 148px;
    }

    @media (min-width: 1920px) and (min-height: 1080px) {
      max-width: 236px;
    }
  }

  @include bp-max-medium-large {
    .c-hero__media {
      position: absolute;
      top: var(--header--height);
      width: 100%;
      height: 100%;
      min-height: 800px;
      padding-bottom: 0;
      z-index: 1;
    }
  }

  &[data-article-hero__layout='50-50'],
  &[data-article-hero__layout='50-100'] {
    .c-hero__content__item {
      @include bp-xlarge {
        transform: translateY(-96px);
      }
    }
  }
}

/* ==========================================================================
  Layout
========================================================================== */

// 50-50

@include bp-medium-large {
  [data-article-hero__layout='50-50'] {
    .c-hero__content {
      order: 1;
      width: 50%;
    }

    .c-hero__media {
      order: 2;
      width: 50%;
      height: auto;
    }
  }
}

@media (min-width: 960px) and (min-height: 620px) and (max-width: 1439px) and (max-height: 899px) and (orientation: landscape) {
  [data-article-hero__layout='50-50'],
  [data-article-hero__layout='50-100'] {
    .c-hero__content {

      h1 {
        font-size: 56px;

        // br {
        //   display: none;
        // }
      }
    }
  }
}

@media (min-width: 1280px) and (min-height: 620px) and (max-width: 1439px) and (max-height: 899px) and (orientation: landscape) {
  [data-article-hero__layout='50-50'],
  [data-article-hero__layout='50-100'] {

    &:not[data-article-hero__size="mini"] {
    .c-hero__content {

      .c-hero__content__item {
        transform: translateY(-120px);
      }

      .c-logo__symbol {
        max-width: 80px;
      }

    }
  }
}
}

// 50-100

@include bp-medium-large {
  [data-article-hero__layout='50-100'] {
    .c-hero__content {
      order: 1;
      width: 50%;
    }

    .c-hero__media {
      position: absolute;
      top: var(--header--height);
      z-index: 2;
      width: 100%;
      height: calc(100vh - var(--header--height));
      min-height: 600px;
    }
  }
}

@media (min-width: 1024px) and (min-height: 768px) and (max-width: 1439px) and (max-height: 899px) and (orientation: landscape) {
  [data-article-hero__layout='50-100'] {
    .c-hero__content h1 {
      font-size: 64px;
    }
  }
}

// 100

[data-article-hero__layout='100'] {
  flex-direction: column;

  @include bp-medium-large {
    .c-hero__content,
    .c-hero__media {
      position: absolute;
      top: var(--header--height);
      z-index: 2;
      width: 100%;
      height: calc(100vh - var(--header--height));
    }

    .c-hero__content {
      z-index: 3;

      @media (min-width: 1024px) and (min-height: 768px) and (max-width: 1439px) and (max-height: 899px) and (orientation: landscape) {
        h1 {
          margin-bottom: 64px;
        }
      }
    }
  }

  @media (min-width: 960px) and (min-height: 1080px) {
    .c-top-filters {
      bottom: 280px;
    }
  }
}

/* ==========================================================================
  Protection
========================================================================== */

// Full

[data-article-hero__protection='full'] {
  .c-hero__content {
    background: var(--color--primary);
  }
}

// Avoid blank left col when no protection set on 50-50 >= @ml

@include bp-medium-large {
  [data-article-hero__layout='50-50'][data-article-hero__protection='none'] {
    .c-hero__content {
      background: var(--color--primary);
    }
  }
}

// Transparency

[data-article-hero__protection='transparency'] {
  .c-hero__content {
    background: rgba(22, 48, 41, 0.26);
  }
}

// Transparency - Green @ max ml

@include bp-max-medium-large {
  [data-article-hero__protection--mobile='transparency--green--max--ml'] {
    .c-hero__content {
      background: rgba(0, 133, 80, 0.64);
    }
  }
}

// Gradient

[data-article-hero__protection='gradient'] {
  .c-hero__content {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    );
  }
}

/* ==========================================================================
  Aspira 'A'
========================================================================== */

// True

[data-article-hero__aspira-a='true'] {
  .c-logo__symbol {
    display: flex;
    margin: 0 auto 8px;
  }

  .c-aspira-a__text {
    display: none;
  }
}

// False

[data-article-hero__aspira-a='false'] {
  .c-logo__symbol {
    display: none;
  }

  .c-aspira-a__text {
    display: inline-flex;
  }
}

/* ==========================================================================
  Image Position
========================================================================== */

// Top

[data-article-hero__img-position='top'] {
  .c-hero__media {
    img {
      object-position: top;
    }
  }
}

// Bottom

[data-article-hero__img-position='bottom'] {
  .c-hero__media {
    img {
      object-position: bottom;
    }
  }
}

/* ==========================================================================
  br
========================================================================== */

[data-article-hero__layout='100'] {
  .c-hero__content {
    br {
      display: none;
    }
  }
}
