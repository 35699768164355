/* ==========================================================================
Tools
  > Lists
========================================================================== */

// Bullet Custom

@mixin t-list--bullet-custom {
  list-style: none;
  margin-left: 0;
  margin-bottom: 40px;

  @include bp-medium {
    margin-bottom: 72px;
  }

  li {
    position: relative;
    margin-bottom: 12px;
    padding-left: 24px;

    &:before {
      content: '';
      background: #444;
      width: 3px;
      height: 3px;
      display: inline-block;
      position: absolute;
      top: 14px;
      left: 0;
    }

    a {
      color: var(--color--text);

      &:hover {
        color: var(--color--primary);
        text-decoration: underline;
      }

      &:focus {
        outline-offset: 2px;
      }
    }
  }
}
