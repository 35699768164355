/* ==========================================================================
Components
  > Loaders
========================================================================== */

.loader {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  height: 60px;
  width: 60px;
  line-height: 60px;
  margin: 0 auto;
  animation: rotation 0.75s infinite linear;
  border-left: 6px solid rgba(101,168,206,0.4);
  border-right: 6px solid rgba(101,168,206,0.4);
  border-bottom: 6px solid rgba(101,168,206,0.4);
  border-top: 6px solid rgba(101,168,206,0.8);
  border-radius: 100%;

  &.loader--sm {
    height: 18px;
    width: 18px;
    border-width: 2px;
    margin-top: -2px;
    margin-right: 8px;
  }
}

@keyframes rotation {
  from { transform:rotate(0deg); }
  to { transform:rotate(359deg); }
}