/* ==========================================================================
   Tools
     > Headings
   ========================================================================== */

// Base

@mixin t-heading--base {
  font-family: var(--font--headings);
  font-weight: 400;
  color: var(--color--text);
}

// h1

@mixin t-heading--h1 {
  @include font-size(80px);
  line-height: 1;
  letter-spacing: -1.28px;

  @include bp-full {
    font-size: 107px;
    line-height: 104px;
    letter-spacing: -3.531px;
  }
}

// h2

@mixin t-heading--h2 {
  font-size: 34px;
  line-height: 42px;
  margin-bottom: calc(32px - 8px);

  @include bp-medium {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1.134px;
  }
}

// h3

@mixin t-heading--h3 {
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.224px;
}

// h4

@mixin t-heading--h4 {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
}

// h5

@mixin t-heading--h5 {
  font-family: var(--font--medium);
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0px;
  margin-bottom: 16px;
}
