/* ==========================================================================
Components
  > Breadcrumbs
========================================================================== */

// Inherited

ul.breadCrumbs {
  @include bp-small-medium {
    float: left;
    margin: 0;
  }

  li {
    display: none;
    font-size: 13px;
    line-height: 1.92308;
    vertical-align: middle;
    list-style: none outside none;
    margin: 0;
    line-height: 1;

    &:last-child {
      display: inline-block;
    }

    @include bp-medium {
      display: inline-block;
    }

    a {
      &:after {
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        text-align: center;
        margin-right: 0.4em;
        vertical-align: -0.05em;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        font-size: 120%;
        margin-right: 0;
        margin-left: 6.25px;
        color: #fff;
        display: none;
        content: '';

        @include bp-medium {
          display: inline-block;
        }
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}