/* ==========================================================================
   #Icon Pod
   ========================================================================== */

.c-icon-pod {
  text-align: center;
  margin-bottom: 0;

  a.pod-link {
    color: #222;
    display: block;

    &:hover {
      color: var(--color--secondary);
      text-decoration: none;
    }
  }

  h3,
  h4 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  .col {
    margin-bottom: 3.5rem;
  }

  p.title {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  img {
    height: 100px;
    width: auto;
    margin-bottom: 1.5rem;
  }
}

// Icon Row

.c-icon-row {
  border-top: 1px solid var(--color--grey--light);
  border-bottom: 1px solid var(--color--grey--light);

  .row {
    margin-bottom: 0;
  }

  .col {
    position: relative;
    text-align: center;
    padding: 16px 0;
    margin: 0;

    a,
    p {
      font-size: 18px;
      font-weight: 500;
    }

    .fal {
      display: block;
      font-size: 44px;
      width: 100%;
      margin-bottom: 1rem;

      @include bp-medium {
        font-size: 56px;
      }
    }
  }
}
