/* ==========================================================================
Components
  > Hamburger - Base
========================================================================== */

.c-hamburger {
  padding: $hamburger-padding-y $hamburger-padding-x;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0 -8px 0 6px;
  overflow: visible;

  &:hover {
    opacity: $hamburger-hover-opacity;
  }

  &.is-active {
    &:hover {
      opacity: $hamburger-active-hover-opacity;
    }

    .c-hamburger-inner,
    .c-hamburger-inner::before,
    .c-hamburger-inner::after {
      background-color: $hamburger-active-layer-color;
    }
  }

  &:focus {
    outline: none;
    background: #fff;

    .c-btn__icon {
      color: var(--color--primary);
      outline: 2px solid var(--color--primary);
      outline-offset: -2px;
      border-radius: 100%;

      @include bp-medium-large {
        outline-offset: 8px;
      }
    }
  }

  @include bp-medium-large {
    justify-content: center;
    width: var(--header--height);
    border-left: 1px solid var(--color--grey--light);
    margin-right: -32px;
    margin-left: 0;
  }

  @include bp-large {
    margin-right: -64px;
  }

  @include bp-xlarge {
    display: none;
  }
}

.c-hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.c-hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}
