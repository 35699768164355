/* ==========================================================================
Settings
  > Global
========================================================================== */

@import 'tools/_t-breakpoints';

// Min

$bp-xsmall: 320px;
$bp-small: 480px;
$bp-small-medium: 540px;
$bp-small-medium-extended: 600px;
$bp-medium: 768px;
$bp-medium-large: 960px;
$bp-large: 1024px;
$bp-xlarge: 1280px;
$bp-xxlarge: 1440px;
$bp-full: 1600px;
$bp-largest: 1920px;

// Max

$bp-max-small: 479px;
$bp-max-small-medium: 539px;
$bp-max-medium: 767px;
$bp-max-medium-large: 959px;
$bp-max-large: 1023px;

:root {
  --gutter: 28px;
  --gutter--alt: 36px;
  --utility--height: var(--tap-target--size);
  --header--height: 80px;
  --header--height--sticky: 80px;
  --main-nav--height: 80px;
  --main-search__input--height: 56px;
  --main-search__button--width: 56px;
  --global--transition: all 200ms ease-in-out;
  --global--radius: 0px;
  --radius--c-form: 0px;
  --padding--wrapper: 40px;
  --padding--wrapper--minor: 16px;
  --padding--wrapper--major: 64px 0 56px;
  --padding--wrapper--quick-link: 48px var(--gutter);
  --spacer: 24px;
  --spacer--alt: calc(40px - 16px);
  --tap-target--size: 48px;
  --thumb--w: 177px;
  --thumb--h: 177px;
  --padding--btn: 0 16px;
  --alert--h--desktop: 48px;
  --filter__direction--height: 88px;
  //--mega-menu__position--top:      calc(var(--utility--height) + var(--header--height) + var(--main-nav--height));

  @include bp-small-medium {
    --filter__direction--height: 58px;
  }

  @include bp-medium {
    --padding--wrapper: 48px;
    --padding--wrapper--minor: 32px;
    --spacer: 32px;
    --spacer--alt: calc(64px - 16px);
    --main-search__input--height: 80px;
    --main-search__button--width: 190px;
  }

  @include bp-medium-large {
    --header--height: 128px;
    --main-nav--height: 128px;
    --padding--wrapper: 80px;
    --padding--wrapper--minor: 48px;
    --spacer: 40px;
    --spacer--alt: calc(112px - 16px);
  }
}
