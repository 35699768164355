:root {
  //--c-ada-carousel-height: 300px;
}

/**
  Container
*/

.carousel {
  .col {
    margin-bottom: 0;
  }
}

/**
  Controls - pause/play, previous, and next buttons
*/

/* All buttons */
.controls button {
  background: none;
  color: rgba(0, 0, 0, 0.7);
  border: 0;
  cursor: pointer;
  transition: all 0.2s linear;
}

.controls button:focus {
  outline: none;
  background-color: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 1);
}

/* Pause/play button */
.controls .rotation-button {
  position: absolute;
  bottom: 5px;
  left: 5px;
  padding: 10px 15px;
  z-index: 1;
  font-size: 20px;
  border-radius: 5px;
}

.controls .rotation-button .pause-container,
.controls .rotation-button .resume-container {
  display: none;
}

/* Previous slide button */
.controls .previous-button {
  position: absolute;
  left: 0;
  top: 45%;
  padding: 5px 15px;
  z-index: 1;
  font-size: 30px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 0;
}

/* Next slide button */
.controls .next-button {
  position: absolute;
  right: 0;
  top: 45%;
  padding: 5px 15px;
  z-index: 1;
  font-size: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 0;
}

/**
  Slides
*/
.slides {
  z-index: 0;
  white-space: nowrap;
  // min-height: var(--c-ada-carousel-height);
  overflow: hidden;
  display: flex;
  align-items: flex-end;

  @include bp-medium {
    align-items: center;
  }

  @include bp-xlarge {
    align-items: flex-start;
  }
}

.slides .slide {
  flex: 0 0 100%;
  position: relative;
  white-space: normal;
}

.slides .slide[aria-hidden='true'] {
  visibility: hidden;
}

.slides .slide .background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/**
  Slide 2 content
*/
.slides .slide .content {
  position: absolute;
  top: 20px;
  left: 15%;
  max-width: 400px;
  width: 50%;
  max-height: 70%;
  padding: 15px;
  white-space: normal;
  color: white;
  background-color: royalblue;
  box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.3);
}

.slides .slide .content h2 {
  margin: 0;
  font-size: 1.7rem;
}

.slides .slide .content p {
  margin: 10px 0 20px 0;
}

.slides .slide .content .cta-link {
  display: inline-block;
  padding: 5px 10px;
  background-color: white;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
  font-weight: 800;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.3);
}

.slides .slide .content .cta-link:focus,
.slides .slide .content .cta-link:hover {
  text-decoration: underline;
  color: black;
}

.slides .slide .content .cta-link:focus {
  outline: 3px solid rgba(0, 0, 0, 1);
}

/**
  Slide dot navigation
*/

.carousel {
  // Nav

  .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 32px auto 0;
    padding: 0;
  }

  // Dot

  .slide-dot {
    display: inline;

    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }

  // Button

  .slide-dot-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background: none;
    border: none;
    cursor: pointer;

    &:after {
      content: '';
      width: 8px;
      height: 8px;
      background-color: var(--color--text);
      border-radius: 100%;
    }

    &:focus {
      outline: 2px solid var(--color--primary);
    }
  }

  .slide-dot-button[aria-current='true'] {
    &:after {
      content: '';
      width: 12px;
      height: 12px;
      background-color: var(--color--primary);
    }
  }
}

.u-bg-color--text {
  .slide-dot-button {
    &:after {
      background-color: var(--color--primary--light);
    }

    &:focus {
      outline: 2px solid var(--color--primary--lightest);
    }
  }

  .slide-dot-button[aria-current='true'] {
    &:after {
      background-color: #fff;
    }
  }
}

/**
  Utilities
*/
.is-visible {
  display: block !important;
}
