/* ==========================================================================
Components
  > Navigation
========================================================================== */

// Main Nav

.c-main-nav {
  display: none;

  @include bp-xlarge {
    display: flex;
    justify-content: flex-end;
    right: -9px;
    margin: 0 96px 0 0;
    width: 100%;
    max-width: 1280px;
    position: relative;

    @media (max-height: 900px) {
      position: unset;
    }

    .c-main-nav__list {
      font-size: 0px;
    }

    > ul {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      padding: 0;

      > li {
        display: inline-flex;
        padding: 0;
        position: relative;

        @media (max-height: 900px) {
          &:not(.drop-menu) {
              position: unset;
            }
          }

        > a {
          display: flex;
          align-items: center;
          text-decoration: none;
          font-family: 'CircularXXWeb-Medium', sans-serif;
          font-size: 19px;
          height: var(--main-nav--height);
          color: var(--color--text);
          position: relative;
          padding: 20px 37px 0 32px;

          @media (min-width: 1280px) and (max-height: 900px) {
            padding-right: 4px;
          }

          &:hover {
            color: var(--color--primary);
            text-decoration: none !important;
          }

          &:focus {
            outline: none;

            &:after {
              @include t-nav-item__border;
            }
          }
        }

        > button {
          display: flex;
          align-items: center;
          justify-content: center;
          background: none;
          border: 0;
          font-size: 12px;
          padding: 20px 0 0 0;
          pointer-events: none;
          position: absolute;
          bottom: 50%;
          right: 0;
          transform: translateY(50%);

          @media (min-width: 1280px) and (max-height: 900px) {
            position: unset;
            transform: none;
          }

          .far {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            width: 32px;
            height: 32px;
            margin-left: 0px;
          }

          &:focus {
            outline: none;

            .far {
              outline: 2px solid var(--color--primary);
              border-radius: 100%;
              outline-offset: -4px;
            }
          }

          &:hover {
            color: var(--color--primary);
          }

          &[aria-expanded='true'] {
            .far {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  @media (min-width: 1590px) {
    margin: 0 auto;
  }
}

// Remove padding on scroll

.scroll-down,
.scroll-up {
  .c-main-nav > ul > li > {
    a,
    button {
      padding-top: 0;
    }
  }
}

.c-main-nav > ul > li {
  &.active,
  &.c-menu__item--active {
    // Bottom border

    > a {
      color: var(--color--primary);

      &:after {
        @include t-nav-item__border;
      }
    }

    .far fa-chevron-down {
      transform: rotate(180deg);
      color: var(--color--primary);
    }
  }

  &.active > a {
    color: var(--color--primary);
  }
}

.scroll-up {
  .c-main-nav > ul > li {
    &.active,
    &.c-menu__item--active {
      > a:after {
        bottom: 0;
      }
    }
  }
}

// Mega Menu

.c-mega-menu {
  display: none;
  position: fixed;
  width: 100vw;
  background: #fff;
  top: var(--header--height);
  left: 0;
  min-height: calc(832px - var(--header--height));
  bottom: auto;
  z-index: 9;
  border-top: 1px solid var(--color--grey--light);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  font-size: initial;

  @media (min-width: 1280px) and (max-height: 900px) {
    position: absolute;
    min-height: 100vh;
  }

  // Header

  header {
    display: flex;
    border-bottom: 1px solid var(--color--grey--light);
    padding: 56px 0 40px 0;

    .c-btn {
      position: relative;
      font-size: 20px;
      background: transparent;
      color: var(--color--text);
      font-family: var(--font--headings);
      order: 2;
      margin: 0 -8px 0 auto;
      padding: 0 8px;
      width: auto;

      &:hover {
        color: var(--color--primary);
      }

      &:focus {
        background: transparent;
        color: var(--color--primary);
      }

      .fal fa-times {
        position: relative;
        top: 3px;
        margin: 0 0 0 8px;
      }
    }

    // Section

    section {
      width: 66.6666666667%;

      h2 {
        // font-family: var(--font--medium);
        font-weight: 600;
        letter-spacing: -0.88px;
        color: var(--color--primary);
        margin: 0 0 12px 0;
      }

      p {
        font-size: 24px;
        line-height: 34px;
        margin: 0;
      }
    }
  }

  // Hold
  .c-menu-footer__hold {
    display: flex;
  }

  // Footer

  footer {
    width: 100%;
    padding-top: 40px;

    figure {
      margin-bottom: 24px;
    }

    .c-btn {
      width: 100%;
    }
  }
}

// Menu Levels

.c-menu__levels {
  display: flex;
  flex-shrink: 0;
  width: calc(100% - 236px);
  min-height: 455px;
  position: relative;
  overflow: hidden;

  .c-menu__level {
    position: absolute;
    top: 0;
    width: 33.333%;
    min-height: 455px;
    padding: var(--gutter) 0 0 0;
    margin-right: var(--gutter);

    &:after {
      content: '';
      background: var(--color--grey--light);
      width: 1px;
      height: 200%;
      position: absolute;
      top: 0;
      right: 0;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      a,
      button {
        display: block;
        font-size: 18px;
        width: 100%;
        text-align: left;
        background: transparent;
        height: var(--tap-target--size);
        line-height: var(--tap-target--size);
        border: none;
        margin: 0;
        padding: 0 16px 0 var(--gutter);
        cursor: pointer;
        color: var(--color--text);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover,
        &:focus,
        &.active {
          color: var(--color--primary);
          background-color: var(--color--primary--lightest);
          outline: none;
        }
      }

      .fa-chevron-right {
        position: relative;
        top: 1px;
        display: inline-block;
        text-align: center;
        font-size: 14px;
        width: 40px;
        height: var(--tap-target--size);
        line-height: var(--tap-target--size);
      }
    }

    &.c-menu__level--1 {
      left: 33.333%;
    }

    &.c-menu__level--2 {
      left: 66.666%;

      &:after {
        display: none;
      }
    }
  }
}

// Split e.g. Locations

.c-menu--split--02 {
  column-count: 2;
  column-gap: 0;
}

.c-menu--split--03 {
  column-count: 3;
  column-gap: 0;
}

.c-menu--split--span {
  column-span: all;
}

// No footer e.g. Locations

.c-mega-menu--no-footer {
  min-height: 780px;

  .c-menu__levels {
    width: 100%;
    min-height: 580px;

    .c-menu__level {
      &.c-menu__level--0 {
        width: 20%;
      }

      &.c-menu__level--1 {
        width: 50%;
        left: 20%;
      }

      &.c-menu__level--2 {
        width: 30%;
        left: 70%;
      }
    }
  }

  footer {
    display: none;
  }
}

// Sticky

.scroll-down,
.scroll-up {
  .c-mega-menu {
    top: var(--header--height--sticky);
  }
  .c-main-nav > ul > li > a {
    height: var(--header--height--sticky);
  }
}

.c-main-nav > ul > li.active .c-mega-menu {
  display: block;
}

// Drop Menu
.drop-menu {
  position: relative;
}

.drop-menu > ul {
  @include shadow(3);
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 16px;
  margin: 0;
  top: var(--header--height);
  background: #fff;
  padding: 8px 0 12px 0;
  border-top: 0;
  z-index: 20;
  width: 275px;
  list-style-type: none;
  border-radius: 0 0 16px 16px;

  li {
    a {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #222;
      text-decoration: none;
      font-size: 18px;
      padding: 8px 16px;

      &:hover {
        color: var(--color--primary);
        background: var(--color--primary--lightest);
        text-decoration: none;
      }
    }
  }
}

.scroll-down,
.scroll-up {
  .drop-menu > ul {
    top: var(--header--height--sticky);
  }
}

.drop-menu.active > ul {
  visibility: visible;
  opacity: 1;
}

// ADA Mega Menu

.c-ada-mega-menu {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  background: #fff;
  top: 176px;
  left: 0;
  height: auto;
  bottom: auto;
  z-index: 9;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .o-container {
    padding: 3.5rem 25px;
    position: relative;

    .c-ada-mega-menu--close {
      position: absolute;
      right: 2rem;
      top: 2rem;
      font-size: 1.2rem;
      background: none;
      border: 0;
      cursor: pointer;

      &:focus,
      &:hover {
        outline: 2px dotted var(--color--primary);
      }
    }

    .c-ada-mega-menu--intro {
      float: left;
      width: 40%;
      padding-right: 10rem;
    }

    .c-ada-mega-menu--links {
      float: left;
      width: 60%;

      ul {
        list-style-type: none;
        margin: 0;
        column-count: 3;

        li {
          margin-bottom: 0.5rem;
        }
      }
    }

    .row {
      float: left;
      width: 60%;
      margin-bottom: 0;

      .col {
        h3 {
          font-size: 20px;
        }
      }

      ul {
        list-style-type: none;
        margin: 0;
      }
    }
  }
}

.c-menu__level--1 .ada-menu-mega,
.c-menu__level--2 .ada-menu-mega {
  display: none;
}
