/* ==========================================================================
   #NAVIGATION - ADA MOBILE MENU
   ========================================================================== */

.nav {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/** Hamburger button */
.menu-toggle-button {
  background: none;
  border: 0;
  font-size: 24px;
  cursor: pointer;
  padding: 20px;
}

.menu-toggle-button .fas {
  width: 20px;
}

/** Mobile menu */
#mobile-menu-modal {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 11;

  @include bp-xlarge {
    display: none !important;
  }
}

/** Close button */
.close-button {
  background: white;
  border: 0;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-top: 0;
  position: absolute;
  left: 0;
  border-left: 1px solid #ddd;
  height: 68px;
  width: 49px;
  z-index: 2;

  @media (min-width: 375px) {
    width: 68px;
  }

  &:focus {
    outline: none;
    background: var(--color--primary);
    color: #fff;
    border-left-color: var(--color--primary);

    span {
      color: #fff;
    }
  }
}

.close-button .fas {
  width: 20px;
}

.menu-container {
  position: relative;
  width: 0;
  height: 100%;
  z-index: 1;
  margin-top: 0;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.ada-menu {
  list-style: none;
  padding: 0 0 calc(var(--tap-target--size) + 16px) 0;
  margin: 0;
  z-index: 2;
  width: 100%;
  min-width: 270px;
}

.ada-menu li {
  width: 100%;
  border-bottom: 1px solid #ddd;
}

.ada-menu li a,
.ada-menu li button {
  position: relative;
  display: block;
  width: 100%;
  padding: 1rem 2rem 1rem 1rem;
  color: var(--color--primary);
  text-decoration: none;
  text-align: left;
  background: #fff;
  border: 0;
  cursor: pointer;
  line-height: 1.4rem;

  &:hover,
  &:focus {
    background: var(--color--primary);
    color: #fff;
  }
}

.ada-menu li .far {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  font-size: 20px;
}

/** Back button on level 1 menus */

.ada-menu li button.back-button {
  background-color: #fff;
  color: var(--color--text);

  &:focus,
  &:hover {
    color: #fff;
    background: var(--color--primary);
  }
}

/** Level 0 (top level) menu */
.menu-level-0 {
  position: absolute;
  left: 0;
  width: 100%;
}

/** Level 1 menu */
.menu-level-1 {
  position: absolute;
  left: 270px;
  width: 100%;
}

.menu-level-0 .ada-menu {
  display: block;
}

.menu-level-1 .ada-menu {
  display: none;
}

/** Level 2 menu */

.menu-level-2 {
  position: absolute;
  left: 270px;
  width: 100%;
}

.menu-level-2 .ada-menu {
  display: none;
}

/** Level 3 menu */

.menu-level-3 {
  position: absolute;
  left: 270px;
  width: 100%;
}

.menu-level-3 .ada-menu {
  display: none;
}

// Prevent page scroll

@media (max-width: 1279px) {
  .is--menu--mobile {
    body {
      overflow: hidden;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }

    .c-modal--menu {
      .c-modal__dialog {
        transform: translateX(0) !important;

        .c-modal__content {
          padding: 0;
          background: transparent;
        }
      }
    }
  }
}

.c-menu__footer {
  position: fixed;
  bottom: 0;
  width: 270px;
  background: #fff;
  padding: 0 1rem;
  height: calc(var(--tap-target--size) + 17px);
  display: flex;
  align-items: center;
  border-top: 1px solid var(--color--grey--light);
  opacity: 0;
  visibility: hidden;

  .c-utility-menu {
    display: flex;
    align-items: center;
    margin: 0;
    width: 100%;
    height: 100%;

    li {
      display: flex;
      align-items: center;
      height: 100%;
      margin: 0;
      height: 100%;

      &:last-child {
        margin-left: auto;
      }
    }
  }
}

.c-menu__footer.show {
  transition: all 320ms ease-in 320ms;
  opacity: 1;
  visibility: visible;
}
