/* ==========================================================================
Utilities
  > Images
========================================================================== */

// Offset

.u-img--offset {
  @media (min-width: 375px) {
    transform: translate(16px, 0);
  }

  @include bp-small-medium {
    transform: translate(32px, -24px);
  }

  @include bp-large {
    transform: translate(32px, -32px);
  }
}

// Round

.u-image--round {
  border-radius: 100%;
}

// Full Width

.u-image__hold {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  min-height: 400px;
}

.u-image__content {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 60px 0;

  h1{
  color: white;
  }
}

.u-image {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;

  > div {
    position: relative;
    height: 100%;
  }

  img {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    object-fit: cover;
  }

  &.u-image--fw {
    width: 100vw;
    left: 50%;  
    margin-left: -50vw;
  }
}
