/* ==========================================================================
Components
  > Labels
========================================================================== */

// Inherited

.label {
  display: inline-block;
  min-width: 1.04167em;
  padding-right: 0.26042em;
  padding-left: 0.26042em;
  margin: 0 8px 8px 0;
  text-align: center;
  background-color: #ebebeb;

  &.label--closeable {
    padding-right: 0;
  }
}