/* ==========================================================================
Components
  > WYSIWYG
========================================================================== */

// Blue Headings (e.g. Awards - Chaining not possible with :not)

.c-article__body--h2 :not(.c-article__header--main) h2 {
  @include t-heading--h4;
  color: var(--color--primary--apa);
  margin-bottom: 16px;
}

.c-article__body--h3 :not(.c-article__header--main) h3 {
  @include t-heading--h4;
  color: var(--color--primary--apa);
  margin-bottom: 16px;
}

// p, ul

.c-article--wysiwyg {

  .c-article__body {
    p {
      margin-bottom: calc(var(--gutter) * 1.5);
    }

    ul {
      @include t-list--bullet-custom;
    }
  }
}
