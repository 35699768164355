/* ==========================================================================
Components
  > Swatches
========================================================================== */

.c-swatch {

  .c-swatch__item {
    display: flex;
    align-items: center;
    position: relative;

    .c-swatch__color {
      width: 50%;
      padding-bottom: 33.333%;
      margin-right: 8px;
    }

    figcaption {
      font-size: 20px;

      span {
        position: absolute;
        bottom: 0;
        left: 0;
        background: #fff;
        font-size: 13px;
        font-weight: 500;
        padding: 2px 4px;
        line-height: 1;
      }
    }
  }
}