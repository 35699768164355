/* ==========================================================================
   #SITEMAP
   ========================================================================== */

ul.c-list--sitemap {
  list-style-type: none;
  position: relative;
  margin: 0;
  padding: 0;

  li {
    a {
      display: block;
      padding: 12px 0;
      width: 100%;
      text-decoration: none;
      border-bottom: 1px solid #ddd;
    }
  }

  ul {
    list-style: none;
    margin: 0 0 0 16px;
    padding: 0;

    @include bp-small-medium-extended {
      margin-left: 24px;
    }
  }
}
