/**
 * = PRINT styles
 *
 * - Imported in global.css (http://www.phpied.com/delay-loading-your-print-css/)
 * - Taken straight from: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L197
 *
 * @TODO:
 * - Add to this?
 *
 * ========================================================================= */

/* stylelint-disable declaration-no-important  */

@media print {
  *,
  *:before,
  *:after,
  *:first-letter,
  p:first-line,
  div:first-line,
  blockquote:first-line,
  li:first-line {
    background: transparent !important;
    color: #000 !important; /* Black prints faster:
								   http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
    margin-left: 10px;
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  /*
	 * Don't show links that are fragment identifiers,
	 * or use the `javascript:` pseudo protocol
	 */

  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: '';
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
	 * Printing Tables:
	 * http://css-discuss.incutio.com/wiki/Printing_Tables
	 */

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .c-header {
    position: relative !important;
    height: 80px !important;
    border-bottom: 1px solid #000 !important;
    padding-bottom: 40px !important;
    margin-bottom: 40px !important;
  }

  .c-logo {
    position: relative;
    top: 0 !important;
    left: 0 !important;
    width: 200px !important;
  }

  /**
	 * HIDE certain elements completely
	 */
  .site-navigation,
  .c-btn--header {
    display: none !important;
  }
}
