/* ==========================================================================
Elements
  > html
========================================================================== */

html {
  background: #fff;
  color: var(--color--text);
  font-family: var(--font--body);
  font-weight: 400;
  font-size: var(--font__size--base);
  line-height: var(--line__height--base);
  scroll-behavior: smooth;
}

body {
  background: #fff;
  padding-top: var(--header--height);
}

b,
strong {
  font-family: var(--font--medium);
  font-weight: normal;
}

body.c-narrow {
  max-width: 1600px;
  margin: auto;
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.15);
}

:focus {
  outline: 2px solid var(--color--primary);
}
