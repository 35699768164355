/* ==========================================================================
Utilities
  > DD
========================================================================== */

// 2 col

.u-dd--2-col {
  @include clearfix;

  dt, dd {
    float: left;
    line-height: 1.4;
    margin: 0 0 8px 0;
  }

  dt {
    width: 50%;
  }

  dd {
    width: calc(50% - 8px);
    float: left;
    padding-left: 8px;
  }
}