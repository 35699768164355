/* ==========================================================================
Components
  > Article
    > Meta
========================================================================== */

.c-article__meta {

  font-size: 0px; // Remove whitespace
  margin-bottom: 6px;

  .c-meta--highlight {
    font-size: 14px;
    font-weight: 500;
    margin: 0 10px 10px 0;
    background: var(--color--primary);
    display: inline-block;
    color: #fff;
    padding: 2px 8px;
  }
}

// Meta - Event

.c-article__meta--event {
  margin-bottom: 0;
  max-width: 640px;
}

.c-article--main {

  // 3 col

  &.l5 {
    .c-article__meta--event {
      .col {

        &:first-child {
          margin-bottom: 8px;

          @include bp-medium {
            margin-bottom: var(--gutter);
          }

          @include bp-large {
            margin-bottom: 8px;
          }

           @include bp-xlarge {
            margin-bottom: var(--gutter);
          }
        }

        @include bp-large {
          width: 100%;
        }

        @include bp-xlarge {
          width: 50%;
        }
      }
    }
  }
}
