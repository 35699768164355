/* ==========================================================================
   Utilities
     > Clear
   ========================================================================== */

.u-clearfix {
  @include clearfix();
}

// Left

.u-clear--l {
  clear: left;
}

// Right

.u-clear--r {
  clear: right;
}

// Both

.u-clear {
  clear: both;
}
