/* ==========================================================================
   Tools
     > Btns
   ========================================================================== */

// Base

@mixin t-btn {
  font-family: 'CircularXXWeb-Medium', sans-serif;
  display: inline-block;
  text-align: center;
  margin: 0;
  cursor: pointer;
  border: none;
  border-radius: var(--global--radius);
  text-decoration: none;
  background-color: var(--color--primary);
  color: #fff;
  overflow: hidden;
  width: calc(100% - var(--gutter));
  padding: var(--padding--btn);
  height: 56px;
  line-height: 56px;
  font-size: 20px;
  white-space: nowrap;
  vertical-align: middle;

  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.24) 0 0); // Darken
    color: #fff;
    text-decoration: none !important;
  }

  &:focus {
    outline: 2px solid var(--color--text);
  }

  @include bp-small {
    width: auto;
  }
}

// Outline

@mixin t-btn--outline {
  background: transparent;
  border: 2px solid var(--color--primary);
  color: var(--color--primary);
  line-height: 50px;

  &:hover {
    color: #fff;
    background-image: none;
    background: var(--color--primary);
  }
}

// Outline - Secondary (Orange)

@mixin t-btn--outline--secondary {
  color: var(--color--secondary);
  border-color: var(--color--secondary);

  &:hover {
    color: #fff;
    background: var(--color--secondary);
  }
}

// Outline - Light

@mixin t-btn--outline--light {
  color: #fff;
  border-color: #fff;

  &:hover {
    color: #444;
    background: #fff;
  }

  &:focus {
    outline-color: #fff;
  }
}

// Header - Base

@mixin t-btn--header--base {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--header--height);
  line-height: var(--header--height);
  text-align: center;
  text-decoration: none;

  &:hover {
    color: #000;
  }
}

// Header - text

@mixin t-btn--header__txt {
  display: none;

  @media (min-width: 375px) {
    display: flex;
    font-size: 16px;
  }

  @include bp-medium-large {
    display: none;
  }
}

// Header - icon

@mixin t-btn--header__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 40px;
  height: 40px;
  text-align: center;
}

@mixin t-btn--remove-default {
  background: none;
  border: none;
  text-align: left;

  &:focus {
    outline: 2px solid var(--color--primary);
  }
}
