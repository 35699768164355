/* ==========================================================================
   Utilities
     > Misc
   ========================================================================== */

// Inherited

.h-disabled {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.h-muted {
  opacity: 0.5 !important;
}

.h-strikethrough {
  text-decoration: line-through !important;
}

.h-caps {
  text-transform: uppercase !important;
}

.h-truncate {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  
  &:after {
    content: '';
    height: 100%;
    width: 25%;
    position: absolute;
    bottom: 0;
    right: 0;
    right: 0;
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, #fff 100%);
    z-index: 1;
  }
}

.h-displayInlineBlock {
  display: inline-block;
}