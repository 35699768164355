/* ==========================================================================
Components
  > Images
========================================================================== */

// Team Member (Hold)

.c-img--team-member__hold {
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}

// Team Member

.c-img--team-member {
  @include fluid-aspect(4 5);
  margin-bottom: 20px;
}

// Team Member (Detail)

@media (min-width: 657px) and (max-width: 1023px) {
  .c-team-member__hold {
    float: left;
    width: 50%;
    max-width: 600px;
    margin: 0 var(--gutter) 16px 0;

    .c-img--team-member {
      margin: 0;
    }
  }
}
