/* ==========================================================================
Tools
  > Cards
========================================================================== */

// Base

@mixin t-card {
  @include t-ui--global;
  position: relative;
  display: flex;
  padding: 24px;
  background: var(--color--tertiary--blue--light);

  ul {
    margin-bottom: 24px;
  }

  &.c-card--bg--white {
    background: #fff;
  }

  &.c-card--bg--tertiary--grey {
    background: var(--color--tertiary--grey);
  }

  // Media (If image)

  &.c-card--image {
    .c-card__media {
      @include fluid-aspect(4 3);
    }
  }

  .c-btn--plain {
    text-align: left;

    @include bp-small {
      margin-left: auto;
      text-align: right;
    }
  }
}

// Media

@mixin t-card__media {
  margin-bottom: 24px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  .c-icon {
    color: var(--color--primary);
    font-size: 64px;
  }

  img {
    object-fit: cover;
    margin: 0;
  }

  // Offset

  &.offset {
    margin: -24px -24px 24px -24px;
    padding-bottom: 8px;
  }

  // Media - Open

  .c-media--open {
    text-align: center;

    img {
      width: auto;
    }
  }

  // Media - Book

  &.c-media--book {
    img {
      max-width: 128px;
    }
  }
}

// Media - LMS

@mixin t-card__media--lms {
  border-bottom: 1px solid rgba(144, 157, 185, 0.33);

  @include bp-max-medium {
    margin: -24px -24px 24px -24px;
    padding-bottom: 8px;
  }

  @include bp-medium {
    margin: -24px 24px -24px -24px;
    padding: 0 24px;
    border-bottom: none;
    border-right: 1px solid rgba(144, 157, 185, 0.33);
  }
}

// Body

@mixin t-card__body {
  p {
    opacity: 0.8;
  }

  a:hover {
    text-decoration: underline;
  }

  // Label

  .c-card__label {
    display: inline-block;
    background-color: var(--color--primary);
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 0 8px;
    min-height: 24px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 16px;

    .c-icon {
      display: inline-block;
      position: relative;
      top: 1px;
      margin: 0 2px 0 0;
      font-weight: 400;
    }
  }

  .h2 {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.224px;
    margin-bottom: 8px;
  }

  .h4 {
    font-size: 18px;
    margin-bottom: 8px;
    color: #444;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

// Action

@mixin t-card__action {
  display: flex;
  padding-top: 24px;

  .c-btn {
    &:hover {
      color: #fff;
    }
  }
}

// Sub Text

@mixin t-card__sub-text {
  padding-top: 24px;

  p {
    margin: 0;
    border-top: 1px solid var(--color--grey--medium);
    padding-top: 24px;
    font-weight: 300;
    font-style: italic;
  }
}

// Image

@mixin t-card--image {
  .c-card__media {
    overflow: hidden;
    margin: -24px -24px 24px -24px;
    border-radius: var(--global--radius) var(--global--radius) 0 0;
  }
}

// No Image

@mixin t-card--no-image {
  .c-card__media {
    display: none;
  }
}

// Borders (default - tertiary blue))

@mixin t-card--borders {
  //border-left: none;

  &:before {
    display: none;
    content: '';
    background: var(--color--primary);
    border-radius: var(--global--radius) 0 0 var(--global--radius);
    width: 8px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

// Vertical

@mixin t-card--vertical {
  flex-direction: column;
  height: 100%;
  padding: 24px;

  // Media (if image)

  &.c-card--image {
    .c-card__media {
      @include fluid-aspect(4 3);
      border-radius: var(--global--radius) var(--global--radius) 0 0;
      width: calc(100% + 48px);
      margin-top: -24px;
      margin-left: -24px;
      margin-right: -24px;
      margin-bottom: 24px;
      min-width: unset;
      min-height: unset;
    }
  }

  .c-card__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  // Body

  .c-card__body {
    flex-grow: 1;
  }
}

// Horizontal

@mixin t-card--horizontal {
  flex-direction: row;

  // Media

  &.c-card--image .c-card__media {
    position: relative;
    margin-right: 24px;
    margin-bottom: -24px;
    padding-bottom: 0; // Let card content determine card height
    min-height: 256px; // If no content, ensure card doesn't collapse
    width: 34.3450479233227%;
    min-width: 192px;
    border-radius: var(--global--radius) 0 0 var(--global--radius);
  }

  // Media - Product

  &.c-card--product {
    .c-card__media {
      width: 112px;
      margin: 0;
      padding: 24px;

      &.u-b {
        border-bottom: none;
        border-right: 1px solid rgba(144, 157, 185, 0.33);
      }
    }
  }

  .c-card__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  // Body

  .c-card__body {
    flex-grow: 1;
  }

  @include bp-medium {
    &.c-card--lms {
      .c-card__action {
        padding-top: 40px;
      }
    }
  }
}
