/* ==========================================================================
Components
  > Accordion
========================================================================== */

.c-ada-accordion {
  margin: 0;
  border-bottom: 1px solid var(--color--grey--light);
}

.c-ada-accordion--header {
  margin: 0;
  position: relative;
  background: #fff;
  color: var(--color--primary);
  font-weight: 400;

  &[class*=' u-background'] button {
    color: #fff;
  }
}

/* Spacing between headers */
.c-ada-accordion--panel + .c-ada-accordion--header {
  margin-top: 5px;
}

/* Actionable button filling entire heading */

.c-ada-accordion--header button {
  display: flex;
  border: 0;
  background: #fff;
  width: 100%;
  text-align: left;
  padding: 23px 0;
  cursor: pointer;
  box-shadow: none;
  color: var(--color--primary);
  outline: none;
  margin: 0;
  font-size: 22px;
  line-height: 30px;

  @include bp-medium {
    font-size: 28px;
    line-height: 34px;
  }

  // Focus

  &:focus {
    outline: none;

    span {
      outline: 2px solid var(--color--primary);
      border-radius: 100%;
    }
  }

  // Icon

  .icon {
    flex-shrink: 0;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    margin-left: auto;
    text-align: center;
    color: var(--color--text);
  }

  &[aria-expanded='true'] .icon {
    transform: rotateX(180deg);
  }
}

/*
  Panels = content areas whose visibility is controlled by headers
*/

.c-ada-accordion--panel {
  display: none;
  margin: 0;
  padding: 0 0 40px 0;
}
