/* ==========================================================================
Components
  > Asides
========================================================================== */

// Inherited

aside:not(.o-sidebar):not(.o-sidebar--r) {
  font-size: 18px;
  line-height: 1.38889;
  padding: 12.5px 0;
  float: none;
  border-top: 3px solid #ebebeb;
  border-bottom: 3px solid #ebebeb;
  margin-bottom: 25px;
  color: var(--color--primary);

  @include bp-medium {

    // Left

    &.aside--left {
      float: left;
      max-width: 40%;
      margin: 0.35em 25px 25px 0;
    }

    // Right

    &.aside--right {
      float: right;
      max-width: 40%;
      margin: 0.35em 0 25px 25px;
    }
  }
}

// Maintains logical order for asides floated right @m

@include bp-medium {
  .u-flex--m {
    .aside--right {
      flex-shrink: 0;
      align-self: flex-start;
    }
  }
}