/* ==========================================================================
Components
  > Alerts
========================================================================== */

.c-alert {
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 11;
  width: 100%;
  background: #fff;
  padding: 8px 16px;
  border-top: 1px solid var(--color--grey--light);

  // Desktop

  &.c-alert--desktop {
    display: none;
    background: #fff !important;
    font-size: 20px;

    @include bp-large {
      display: block;
      padding: 16px 0;
      overflow: hidden;
      text-align: center;

      .c-alert__title,
      .c-alert__subtext {
        max-width: 48%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  // Mobile

  &.c-alert--mobile {
    font-size: 18px;
    text-align: center;
    padding: 6px 0;
    box-shadow: none;
    background: #fff !important; // temp

    @include bp-large {
      display: none;
    }
  }

  .o-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  // Close

  .c-btn--close__alert {
    height: 100%;
  }

  // Icons

  .fal {
    font-size: 24px;

    .fa-times {
      margin-right: 0;
    }
  }

  // Media

  .c-alert__media {
    flex-shrink: 0;
    width: 64px;
    margin-right: 16px;
    text-align: right;

    figure {
      @include fluid-aspect(3 2);
      margin: 0;
    }
  }

  // Title

  .c-alert__title {
    font-family: var(--font--medium);
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 24px;
    margin-right: 16px;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  // Subtext

  .c-alert__subtext {
    margin-right: 24px;
  }

  // Link

  a {
    text-decoration: underline;
  }
}
