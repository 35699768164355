/* ==========================================================================
   #CONNECT
   ========================================================================== */

.c-connect {
  margin-bottom: var(--gutter);
  padding: 32px;
  background: var(--color--tertiary--blue--light);

  &.u-bg-color--white {
    padding: 0;
  }

  ul {
    margin: 0;

    li {
      line-height: 1.2;
      margin-bottom: 12px;

      span {
        position: relative;
        top: 1px;
        margin-right: 1rem;
        float: left;
        margin-bottom: 4px;
        color: var(--color--grey);
      }

      a {
        overflow: auto;
        color: var(--color--text);
        text-decoration: none;

        &:hover {
          color: #000;
        }
      }
    }
  }
}
