/* ==========================================================================
Components
  > Header 
========================================================================== */

// Skip to Content

.skip-to-content-link {
  background: var(--color--primary);
  height: 40px;
  left: 50%;
  top: 0px;
  padding: 8px;
  position: absolute;
  transform: translate(-50%, -1000px);
  transition: transform 0.3s;
  z-index: 999;
  color: #fff;
  font-size: 18px;

  &:focus {
    transform: translate(-50%, 0%);
  }

  &:hover,
  &:active {
    color: #fff;
  }
}

// Header

.c-header {
  transition: transform 400ms;
  width: 100%;
  margin: 0;
  background: #fff;
  z-index: 1;

  .c-header-top {
    @include clearfix;
    transition: all 400ms;
    display: flex;
    align-items: center;
    height: var(--header--height);
    padding: 0 32px;

    @include bp-large {
      padding: 0 64px;
    }
  }

  // Logo

  .c-logo {
    transition: all 0.4s;
    position: absolute;
    left: 32px;
    width: 104px;
    margin-right: var(--gutter);
    z-index: 1;

    @include bp-medium-large {
      left: 64px;
      width: 148px;
    }

    &:focus {
      outline-offset: 8px;
    }
  }

  // Fixed
  &.c-header--fixed {
    position: fixed;
    z-index: 10;
    right: 0;
    left: 0;
    top: 0;

    @media (min-width: 1280px) and (max-height: 900px) {
      position: absolute;
    }
  }
}

// Scroll Down

.scroll-down {
  .c-header {
    transform: translate3d(0, -100%, 0);

    @media (min-width: 1280px) and (max-height: 900px) {
      transform: none;
    }
  }
}

// Scroll Up

.scroll-up {
  .c-header {
    @include shadow(1);
    transform: none;
  }
}

// Down and Up

.scroll-up,
.scroll-down {
  .c-header-top {
    height: var(--header--height--sticky);

    @include bp-medium-large {
      .c-logo {
        width: 128px;
      }
    }
  }
}

.c-logo {
  display: flex;
  flex-shrink: 0;

  img {
    width: 100%;
    height: auto;
  }
}

.c-menu-btn {
  text-decoration: none;
  font-size: 25px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  color: var(--color--grey--dark);

  @include bp-large {
    display: none;
  }
  &.active {
    color: var(--color--grey);
  }

  &:hover {
    text-decoration: none;
  }
}

// Main Menu Wrapper

.c-header__controls {
  display: flex;
  height: var(--header--height);
  line-height: var(--header--height);
  margin-left: auto;

  @include bp-large {
    margin: 0;
  }
}

// Buttons

.c-btn--header {
  @include t-btn--header--base;

  .c-btn__text {
    @include t-btn--header__txt;
  }

  .c-btn__icon {
    @include t-btn--header__icon;
  }
}

.scroll-down,
.scroll-up {
  .c-btn--header {
    height: var(--header--height--sticky);
  }
}

// Site Search

.c-site-search {
  background: #fff;
  position: relative;

  &.c-search--primary {
    input[type='text'] {
      @include shadow(4);
      padding-right: calc(var(--main-search__button--width) + var(--gutter));
      background: #fff;
      height: var(--main-search__input--height);
      line-height: var(--main-search__input--height);
    }

    button {
      font-size: 20px;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      cursor: pointer;
      border: none;

      color: #fff;
      background: var(--color--primary);
      width: var(--main-search__button--width);
      height: var(--main-search__input--height);
      line-height: var(--main-search__input--height);

      &:focus {
        outline: none;

        .far {
          outline-offset: 16px;
          outline: 2px solid #fff;
          border-radius: 100%;
        }
      }
    }
  }
}

// Utility Menu

.c-utility-menu-container {
  transition: opacity 320ms ease-in 128ms;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  pointer-events: none;
  display: none;

  @include bp-medium-large {
    display: block;
    padding: 0 32px;
  }

  @include bp-large {
    padding: 0 64px;
  }

  @include bp-xlarge {
    top: 16px;
    transform: translateY(0);
  }

  .o-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: 1280px;
    padding: 0;
  }

  a {
    text-decoration: none;
  }
}

// Hide on scroll

.scroll-down,
.scroll-up {
  .c-utility-menu-container {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
}

// Sites menu in Utility Menu

.c-sites-dropdown {
  position: relative;
  float: left;
  transition: all 0.2s ease-in-out;
  margin: 0 32px 0 0;

  &.active {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: var(--color--text);
    }
  }

  > a {
    display: inline-block;
  }

  a {
    color: var(--color--text);
    font-size: 14px;
  }

  button {
    cursor: pointer;
    color: var(--color--text);
    font-size: 16px;
    vertical-align: middle;
    background: none;
    border: 0;
    padding: 8px;
    margin: 0;

    &:hover {
      text-decoration: none;
      color: #000;
    }

    .far fa-chevron-down {
      font-size: 0.625rem;
      font-weight: 800;
    }
  }
}

.c-sites-dropdown-content {
  display: none;
  position: absolute;
  top: 46px;
  width: 275px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 0;
  z-index: 3;
  background-color: #fff;
  border-radius: 0 0 16px 16px;

  ul {
    @include shadow(3);
    border-radius: 0 0 16px 16px;
    margin: 0;
    padding: 8px 0 12px 0;

    li {
      width: 100%;
      list-style-type: none;
      line-height: initial;
      margin: 0;
      position: relative;

      a {
        padding: 8px 16px;
        display: block;
        line-height: 24px;

        &:hover {
          text-decoration: underline;
          color: #000;
        }
      }
    }
  }
}

.c-sites-dropdown.active .c-sites-dropdown-content {
  display: block;
}

.c-utility-menu {
  pointer-events: auto;
  list-style-type: none;

  @include bp-medium-large {
    display: flex;
    margin: 0 calc(var(--header--height) * 2) 0 0;
  }

  @include bp-large {
    //margin-right: calc(64px + 96px);
  }

  @include bp-xlarge {
    margin-right: 96px;
  }

  @media (min-width: 1590px) {
    margin-right: 0;
  }

  li {
    display: inline-block;
    margin-left: 22px;
    font-size: 14px;

    a {
      color: var(--color--text);
      font-size: 14px;
      font-family: var(--font--body);

      &:hover {
        text-decoration: none !important;
        color: var(--color--primary);
      }
    }

    &:last-child {
      a,
      p {
        font-size: 18px;
        margin: 0;
      }
    }
  }
}

// CTA Buttons

.c-cta-btns {
  a {
    font-size: 18.667px;

    &:last-child {
      margin-left: 16px;
    }
  }
}

.c-header--items {
  display: none;

  @include bp-large {
    display: block;
    font-size: 0px; // Removes whitespace
  }
}

.c-header-menu-wrapper {
  display: flex;
  margin-left: auto;
}
