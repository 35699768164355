/* ==========================================================================
Utilities
  > Print
========================================================================== */

@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  body {
    background: #fff;
    margin-top: 0 !important;
    font-size: 14px;
  }


  a,
  a:visited {
    text-decoration: underline;
  }

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 2cm;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  nav,
  .c-header__controls,
  .c-utility-menu-container,
  .c-search-box-container,
  .c-top-header,
  .c-menu-btn,
  .c-search-cart_container,
  .c-standard-banner,
  .c-footer-utility,
  .c-footer,
  .c-footer-logo,
  .c-footer-address br,
  .c-footer h3,
  .footer-nav,
  .c-social-icons,
  .c-call-us,
  .c-search-drop,
  .c-toolbar,
  .c-btn,
  .c-product-thumbnails__wrapper,
  .quick-nav,
  #components,
  #accessories,
  #resources,
  .u-noprint,
  #c-back-to-top,
  .c-featured-full,
  .reveal-above,
  .reveal-below,
  .c-helper-full,
  #c-chat-feature,
  .c-pagination-wrapper,
  .c-mailing-list,
  .c-helper-row,
  .c-mega-search,
  .c-article-image-title,
  .c-pagination,
  .addthis_inline_share_toolbox,
  .c-featured-news-events,
  .c-breadcrumb,
  .c-cookie,
  .c-alert,
  .c-header--items,
  .o-sidebar,
  .c-sidebar-nav--mobile {
    display: none !important;
  }
}
