/* ==========================================================================
Tools
  > Heroes
========================================================================== */

// Light

@mixin t-hero--light {
  .c-hero-content {
    h2,
    p,
    ul,
    ol {
      color: var(--color--text);
    }

    .c-btn {
    }
  }
}
