/* ==========================================================================
Components
  > Search
========================================================================== */

// Mobile

.c-search__overlay {
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  overflow: hidden;
  padding: var(--gutter);
  display: none;
  transition: opacity 0.2s ease-in-out;

  .c-site-search {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - var(--gutter) * 2);
    max-width: 844px;
  }

  .close-button--search {
    position: absolute;
    right: 0;
    top: 0;
  }

  button.c-search-btn {
    position: absolute;
    right: 0;
    bottom: 25px;
    background: none;
    color: #222;
    border: 0;
    font-size: 1.5rem;
    cursor: pointer;

    span {
      color: #222;
    }
  }
}
