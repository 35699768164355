/* ==========================================================================
   Utilities
     > Width
   ========================================================================== */

// 80

.u-w--80 {
  width: 80px;
}

// Max 96

.u-mw--96 {
  max-width: 96px;
}

// Max 120

.u-mw--120 {
  max-width: 120px;
}

// Max 148

.u-mw--148 {
  max-width: 148px;
}

// Max 160

.u-mw--160 {
  width: 100%;
  max-width: 160px;
}

// Max 640

.u-mw--640 {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  float: none !important;
}

@include bp-small {
  .u-mw--50--s {
    max-width: 50%;
  }
}
