/* ==========================================================================
Components
  > Style Guide
========================================================================== */

.c-style-guide {
  > article {
    display: none;

    &.is--visible {
      display: block;
    }
  }
}