/* ==========================================================================
   #References
   ========================================================================== */

.c-references {
  border-top: 1px solid var(--color--grey--light);
  padding-top: 24px;
  margin-top: 24px;

  ol, ul {
    margin-bottom: 0;

    li {
      border-bottom: 1px solid var(--color--grey--light);
      padding-bottom: 24px;
      margin-bottom: 24px;
      display: none;

      &:nth-child(-n+3) {
        display: list-item;
      }

      &:nth-child(3),
      &:last-child {
        border-bottom: none;
        margin-bottom: 8px;
      }
    }
  }

  ul {
    list-style-type: none;
  }

  // Active

  &.active {

    ul li {
      display: block;
    }

    ol li {
      display: list-item;

      &:nth-child(3) {
        border-bottom: 1px solid var(--color--grey--light);
        margin-bottom: 24px;
      }
    }
  }
}
